@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;


body{
  .js_isLoaded{

  }

}

.js_loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  visibility: visible;
  opacity: 1;
  transition: opacity .8s, visibility .8s;
  z-index: var.$loading-layer;
  .js_isLoaded & {
    visibility: hidden;
    opacity: 0;
  }
}

.js_loader_unit{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  //
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}

.js_loading_logo{
  width: 30vw;
  max-width: 300px;
  margin: 0 auto 20px;

  svg{
    fill: #fff;
  }
}

.js_loader_progress {

  width: 100%;
  height: 1px;
  .js_isLoaded & {
    visibility: hidden;
    opacity: 0;
  }
}
.js_loader_progress_bar {
  background: #000;
  height: 100%;
  width: 0;
  transition: width .4s;
}
.js_loader_progress_number {
  text-align: center;
  font-size: 10px;
  width: 100%;
  margin-top: 10px;
}

//
// ------------------------------------------------------------

.loading_wrapper{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  z-index: var.$loading-layer;
  background: var.$brand-main;
}

.loading_logo {
  fill: #3d3a39;
}