@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

//  README
// ------------------------------------------------------------
//  ユーティリティ用のフォントサイズクラスを使用する場合「$utilityFontsizeClass」を「true」にする
//  $endValueはpxの最大値を表す
// ------------------------------------------------------------

$utilityFontsizeClass: true !default;
$endValue: 30 !default;

// padding class
// ------------------------------------------------------------

@for $i from 0 through $endValue {
  @if $utilityFontsizeClass {
    .fz_#{$i} {
      font-size: $i + px !important;
    }
    @include mix.max-media(var.$screen-xlg) {
      .fz_#{$i}_xlg {
        font-size: $i + px !important;
      }
    }
    @include mix.max-media(var.$screen-lg) {
      .fz_#{$i}_lg {
        font-size: $i + px !important;
      }
    }
    @include mix.max-media(var.$screen-mlg) {
      .fz_#{$i}_mlg {
        font-size: $i + px !important;
      }
    }
    @include mix.max-media(var.$screen-slg) {
      .fz_#{$i}_xlg {
        font-size: $i + px !important;
      }
    }
    @include mix.max-media(var.$screen-md) {
      .fz_#{$i}_md {
        font-size: $i + px !important;
      }
    }
    @include mix.max-media(var.$screen-6ls) {
      .fz_#{$i}_6ls {
        font-size: $i + px !important;
      }
    }
    @include mix.max-media(var.$screen-5ls) {
      .fz_#{$i}_5ls {
        font-sizxxe: $i + px !important;
      }
    }
    @include mix.max-media(var.$screen-sm) {
      .fz_#{$i}_sm {
        font-size: $i + px !important;
      }
    }
    @include mix.max-media(var.$screen-msm) {
      .fz_#{$i}_msm {
        font-size: $i + px !important;
      }
    }
    @include mix.max-media(var.$screen-xs) {
      .fz_#{$i}_xs {
        font-size: $i + px !important;
      }
    }
  }
}

// 独自追加
// ------------------------------------------------------------
