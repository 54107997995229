@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.introduction_list{
  display: flex;
  flex-flow: row wrap;
  column-gap: 8px;
  row-gap: 8px;
  align-items: flex-start;
  // 

  dt{
    width: calc(100% - 80% - 4px);
    font-size: 10px;
    text-align: center;
    padding: 0.41666667em;
    color: #fff;
    background-color: #9FA0A0;

    &:last-of-type{
      padding-right: 0;
      padding-left: 0;
    }
  }

  dd{
    width: calc(100% - 20% - 4px);
    font-size: 10px;

    p{
      font-size: 10px;
      margin-bottom: 8px;

      @include mix.min-media(var.$screen-sm){
        font-size: 12px;
      }

      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}

.form_caution_list{
  width: 100%;
  .form_caution_list_body{
    max-width: 350px;
    margin: 0 auto;
    li{
      position: relative;
      padding-left: 12px;

      p{
        font-family: var.$font-family-base;
        font-size: 10px;
        font-weight: bold;
      }
    }

    li:before{
      position: absolute;
      left: 0;
      top: -10%;
      bottom: 50%;
      content: '●';
      color: #9FA0A0;
      font-size: 20px;
    }
  }
}