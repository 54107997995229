@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

$ui-maxHeight: 80px;

.global_ui{
  position: fixed;
  z-index: var.$zindex-header;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: var.$screen-md;
  max-height: $ui-maxHeight;
  margin: 0 auto;
  padding: 8px 0;
  background-color: rgba(255,255,255,.8);
  transition: all .3s ease;

  &.js_isHidden{
    transform : translate(0%,101%);
  }

}

.global_ui_body{
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}

.global_ui_item{
  position: relative;
  width: calc(100% / 2);
  padding: 6px 0;

  @include mix.max-media(var.$screen-msm){
    padding: 0;
  }

  &:last-child{
    .ui_icon{
      img{
        width: 24px;
      }
    }
  }

  &:before{
    position: absolute;
    top: 0;
    right: -1px;
    bottom: 0;
    content: '';
    height: 100%;
    width: 2px;
    border-radius: 3px;

    background-color: #000;
  }

  &:last-child:before{
    display: none;
  }

  a{
    @include mix.setTextLinkColor(#000);
    @include mix.setLinkHoverFadeAnime();
  }
// FIXME
  .ui_icon{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    width: 100%;
    text-align: center;

    img{
      width: 30px;
      max-width: 100%;
      padding-bottom: 4px;
    }
  // FIXME
    .ui_icon_figcaption{
      text-decoration: none;
      font-family: var.$font-family-base;
      font-size: var.$font-size-base;
      font-weight: bold;
      text-align: center;

      @include mix.max-media(var.$screen-msm){
        font-size: 11px;
      }
    }
  }
}