@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

//fullpage.js の上書き用css
// ============================================================

.fp-tableCell{
  display: flex;
  align-items: center;
  width: 100%;
}