@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.privacy_container{
  width: 100%;
  margin: 0 auto;
  padding: 88px 20px 100px 20px;

}

.privacy_inner{
  width: 100%;
  max-width: 100%;
  background-color: #fff;
  padding: 2rem 1.5rem;;
}

.privacyUnit{
  margin-bottom: 1rem;

  &:last-child{
    margin-bottom: 0;
  }
}

.privacy_list01{
  list-style-type: lower-latin;
  padding-left: 2rem;
  margin-bottom: 0.75rem;
  li{
    margin-bottom: 1rem;
    line-height: 1.4;
    &:last-child{
      margin-bottom: 0;
    }
  }

  ol{
    list-style-type: lower-roman;
    padding-left: 1rem;
    ol{
      list-style-type: lower-roman;
      padding-left: 1rem;
    }
  }
}

.privacy_list02{
  list-style-type: disc;
  padding-left: 2rem;
  margin-bottom: 0.75rem;

  li{
    margin-bottom: 1rem;
    line-height: 1.4;
    &:last-child{
      margin-bottom: 0;
    }
  }
}
