@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.heading01,
.heading02,
.heading03,
.heading04{
  font-family: var.$font-family-base;
  font-weight: bold;
  text-align: center;
}

.heading01{
  font-size: clamp( 48px,12.75vw,130px);

}

.small_heading{
  font-size: .75em;
  font-weight: bold;
}

.heading02{
  font-size: 24px;
}

.heading03{
  display: inline-block;
  position: relative;
  font-size: 16px;

  @include mix.min-media(var.$screen-sm){
    font-size: 20px;
  }

  &:before,
  &:after{
    display: inline-block;
    content: '';
    position: absolute;
    top: 50%;
    bottom: 50%;
    width: 50px;
    height: 1px;
    background-color: #000;
  }

  &:before{
    right: 110%;
  }
  &:after{
    left: 110%;
  }
}

.heading04{
  color: #fff;
  font-size: var.$font-size-base;
  text-align: left;
}


.heading05{
  font-family: var.$font-family-base;
  font-size: 18px;
  border-top: 2px solid var.$brand-main;
  border-bottom: 2px solid var.$brand-main;

  padding: 1rem 0;
  margin-bottom: 2rem;
}

.heading06{
  font-family: var.$font-family-base;
  font-size: 1.2rem;
  color: #000;
  margin-bottom: 1.5rem;
}


// display: inline-block;の親要素として使用する。
// ============================================================

.heading_container{
  width: 100%;
  text-align: center;
}