@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.btn_group_center{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: baseline;
}

// btn01
// ============================================================

.btn01{
  @include mix.resetBtnStyle;
  width: 75%;
  background-color: var.$brand-main;
  border-radius: 5px;
  padding: .6em 0;
  margin: 0 auto;
  max-width: 240px;
  line-height: 1.1;

  @include mix.min-media(var.$screen-sm){
    max-width: 270px;
  }

  a{
    @include mix.setTextLinkColor(#fff);
    display: block;
    height: 100%;
  }

  p{
    font-size: 14px;
    text-align: center;
    color: #fff;
  }
}


// btn02
// ============================================================

.btn02{
  width: 55%;
  background-color: #595757;
  border-radius: 1em;
  padding: .25em 0 .2em;
  margin: 0 auto;
  max-width: 230px;

  a{
    @include mix.setTextLinkColor(#fff);
    display: block;
    height: 100%;
  }

  p{
    font-size: 12px;
    text-align: center;
    color: #fff;
  }
}

// form_btn
// ============================================================

.form_btn{
  display: block;
  cursor: pointer;
  width: 100%;
  display: block;
  font-family: var.$font-family-base;
  font-size: 1rem;
  text-align: center;
  color: #fff;
  padding: 1.2rem;

  input[type=file],
  input[type=button],
  button[type=submit]{
    display: none!important;
    appearance: none;
  }

}

//login_btn
// ============================================================

.login_btn{
  @include mix.resetBtnStyle;
  @include mix.setTextLinkColor(#000);
  @include mix.setLinkHoverFadeAnime();
  font-family: var.$font-family-base;
  width: 75%;
  border: 1px solid var.$brand-main;
  border-radius: 5em;
  padding: .7em 0 .6em;
  margin: 0 auto;
  max-width: 240px;
  line-height: 1.1;
  text-align: center;

  @include mix.min-media(var.$screen-sm){
    max-width: 270px;
  }

  &:hover{
    background-color: var.$brand-main;
    color: #fff;
    border: 1px solid transparent;
  }

}
