@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.formUnit{
  background-color: #fff;
}

.form_title{
  width: 100%;
  font-size: 20px;
  text-align: center;
  color: #000;
  background-color: var.$brand-main-lighter;
  padding: function.px2rem(8px) function.px2rem(12px);
}

.required{
  color:#EA5514;
  font-size: 0.65em;
  font-weight: normal;
  margin-left: .5em;
}

.fileName{
  font-family: var.$font-family-base;
  font-size: .8rem;
  color: #333;
  font-weight: bold;
}

.fileName_lead{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.name_filed{
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1rem;

  input[type=text]{
    max-width: 100%;
    border: none;
    border-radius: 5px;
    background-color: rgba($color: var.$brand-main-lighter, $alpha: .4);
    font-size: 16px;
    padding: 1rem .9rem; 

    &::placeholder{
      color: #7C7C7C;
    }
  }
}

.email_filed{

  input[type=email]{
    width: 100%;
    border: none;
    border-radius: 5px;
    background-color: rgba($color: var.$brand-main-lighter, $alpha: .4);
    font-size: 16px;
    padding: 1rem .9rem;

    &::placeholder{
      color: #7C7C7C;
    }
  }
}

.submit_container{
  width: 100%;
  max-width: 100%;
  background-color: #EFEFEF;
  padding-top: 12px;
  padding-bottom: 24px;
}


// jsによってフォームに選択されたファイル名を表示させる
// ============================================================
.js-upload-filename01,
.js-upload-filename02{

  color: #9FA0A0;


  &.js_isActive{
    color: #000;
 
  }
}
