@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.text_bold{
  font-weight: bold;
}

.alignRight{
  text-align: right;
}

.textColor_error{
  color: var.$brand-error !important;
}