@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.header{
  position: fixed;
  z-index: var.$zindex-header;
  top: 0;
  left: 0;
  right: 0;
  // 
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  // 
  width: 100%;
  max-width: var.$screen-md;
  margin: 0 auto;
  padding: 0 20px;
  height: var.$header_H;
  max-height: var.$header_H;
  transition: all .3s ease;
  background-color: rgba(255,255,255,.7);

  @include mix.max-media(var.$screen-msm){
    height: 44px;
    max-height: 44px;
  }

  &.js_isHidden{
    transform : translate(0%,-101%);
  }

}

.brand_logo{
  min-width: 153px;
  max-width: 250px;

  @include mix.max-media(var.$screen-msm){
    min-width: 120px;
  }
  
  @include mix.min-media(var.$screen-sm){

    min-width: 200px;
  }

  a{
    @include mix.setTextLinkColor(#000);
    display: block;
    height: 100%;

    svg{
      vertical-align: bottom;
    }
  }
}