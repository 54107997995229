@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.table01{
  text-align: left;
  color: #fff;
  thead{
    tr{
      th{
        padding-bottom: 12px;
      }
    }
  }  
  tbody{
    tr{
      display: block;
      margin-bottom: 12px;

      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  th{
    font-weight: bold;
  }
  td{
    padding-left: 20px;
  }
}

// table02
// ============================================================

.table02{
  display: block;
  border: 1px solid var.$brand-main-lighter;

  thead,tbody,tr,th,td{
    display: block;
    width: 100%;
  }

  tr{
    border-bottom: 1px solid var.$brand-main-lighter;
    padding: 1.5rem 2rem;
  }

  th{
    font-family: var.$font-family-base;
    font-size: 1.2rem;
    text-align: left;
  }
}