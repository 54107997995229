@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.top_hero{
  width: 100%;
  height: 100vh;
  background-color: #fff;
}

.hero_inner{
  width: 100%;
  max-width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  row-gap: 2em;
}

.hero_lead{
  font-family: var.$font-family-base;
  font-size: clamp(23px, 6vw, 62px);
  text-align: center;
}

.hero_image{
  width: 25%;
  margin: 0 auto;

  img{
    width: 100%;
    max-width: 100%;
  }
}