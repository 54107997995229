@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

html{
  font-size: function.fzpx2vw(var.$font-size-heading);
}

body{
  font-family: var.$font-family-base;
  color: var.$text-color;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  background-color: var.$body-background;
}

h1,h2,h3,h4,h5{
  font-size: function.fzpx2vw(var.$font-size-heading);
  line-height: var.$line-height-heading;
  color: var.$headings-color;
}

p{
  font-family: var.$font-family-base;
  //font-size: function.fzpx2vw(var.$font-size-base);
  line-height: var.$line-height-base;
  color: var.$text-color;
}

ul,ol{
  list-style: none;
}

figure{
  margin-bottom: 0 !important;
}