@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;


.layer_contents{
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var.$zindex-contents;
  background-color: transparent;
  padding-top: 100px;
}

.layer_evelation02{
  position: fixed;
  z-index: var.$zindex-evelation01;
  top: 0;
  left: 0;
  right: 0;
  background-color: #EFEFEF;
  // 
  width: 100%;
  max-width: var.$screen-md;
  height: 100vh;
  overflow: hidden;
  margin: 0 auto;

  @include mix.max-media(var.$screen-sm){
    height: 100lvh;
  }
}

.layer_evelation01{
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  // 
  width: 100%;
  max-width: var.$screen-md;
  height: 100vh;
  overflow: hidden;
  margin: 0 auto;
  background-color: var.$brand-main-lighter;

  @include mix.max-media(var.$screen-sm){
    height: 100lvh;
  }


  &:before{
    display: block;
    content: "";
    position: absolute;
    //
    top: 0;
    left: 0;
    transform : translate(-100%,-100%) rotate(45deg);
    //
    width: 150%;
    height: 150%;
    //
    background-color: var.$brand-main;
    transition: all 1.0s ease;

    @include mix.min-media(var.$screen-sm){
      width: 130%;
    }
  }

  &.js_isActive{
    &:before{

      transform : translate(-50%,-50%) rotate(45deg);
    }
  }

}