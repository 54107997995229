@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

.wrapper{
  width: 100%;
  max-width: var.$screen-md;
  margin: 0 auto;
}

.container{
  margin: 0 auto;
}

.topContainer{
  display: flex;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 10vh;
  @media screen and( max-height: 630px) {
    margin-bottom: 20vh;
  }
}

.container_inner{
  width: 100%;
  max-width: 500px;
  height: auto;
  margin: 0 auto;
}

.container_innerLayout01{
  padding: calc(clamp(20px,10vw + var.$header_H,100px)) clamp(20px,7.5vw,100px)  100px;
  max-width: none;
}

.container_innerLayout02{
  padding: calc(clamp(20px,10vw + var.$header_H,100px)) clamp(20px,7.5vw,100px) 100px;
  max-width: none;
}