@charset "UTF-8";
@import "../../node_modules/animate.css/animate.min.css";
* {
  box-sizing: border-box;
}
@media screen and (max-width: 1980px) {
  * {
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1366px) {
  * {
    box-sizing: border-box;
  }
}
@media screen and (max-width: 1024px) {
  * {
    box-sizing: border-box;
  }
}
@media screen and (max-width: 980px) {
  * {
    box-sizing: border-box;
  }
}
@media screen and (max-width: 834px) {
  * {
    box-sizing: border-box;
  }
}
@media screen and (max-width: 667px) {
  * {
    box-sizing: border-box;
  }
}
@media screen and (max-width: 568px) {
  * {
    box-sizing: border-box;
  }
}
@media screen and (max-width: 428px) {
  * {
    box-sizing: border-box;
  }
}
@media screen and (max-width: 375px) {
  * {
    box-sizing: border-box;
  }
}
@media screen and (max-width: 320px) {
  * {
    box-sizing: border-box;
  }
}

/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

html {
  font-size: clamp(10px, 3.4666666667vw, 16px);
}

body {
  font-family: "Helvetica Neue", "Roboto", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Meiryo, sans-serif;
  color: #3b3b3b;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  background-color: #F8F8F8;
}

h1, h2, h3, h4, h5 {
  font-size: clamp(10px, 3.4666666667vw, 16px);
  line-height: 1.4;
  color: #333;
}

p {
  font-family: "Helvetica Neue", "Roboto", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Meiryo, sans-serif;
  line-height: 1.4;
  color: #3b3b3b;
}

ul, ol {
  list-style: none;
}

figure {
  margin-bottom: 0 !important;
}

.js_animation_0 {
  opacity: 0;
}
.js_animation_0.js_animation_sequence {
  opacity: 1;
}

.js_animation_25 {
  opacity: 0;
}
.js_animation_25.js_animation_sequence {
  opacity: 1;
}

.js_animation_35 {
  opacity: 0;
}
.js_animation_35.js_animation_sequence {
  opacity: 1;
}

.js_animation_50 {
  opacity: 0;
}
.js_animation_50.js_animation_sequence {
  opacity: 1;
}

.js_animation_75 {
  opacity: 0;
}
.js_animation_75.js_animation_sequence {
  opacity: 1;
}

.js_animation_sequence_target {
  opacity: 0;
}
.js_animation_sequence_target.js_animation_sequence {
  opacity: 1;
}

.js_loading_cb_animation {
  opacity: 0;
}

.js_animation_fadeInDown.js_isActive {
  opacity: 1;
  animation: fadeInDown;
  animation-duration: 1s;
}

.js_animation_fadeOut {
  opacity: 1;
  animation: fadeOut;
  animation-duration: 1s;
}

.js_animation_sequence_target.js_isActive {
  opacity: 1;
  animation: fadeInDown;
  animation-duration: 1s;
}

.js_animation_fadeIn.js_isActive {
  opacity: 1;
  animation: fadeIn;
  animation-duration: 2s;
}

.js_animation_bounceIn.js_isActive {
  opacity: 1;
  animation: bounceIn;
  animation-duration: 1s;
}

.js_animation_zoomIn.js_isActive {
  opacity: 1;
  animation: zoomIn;
  animation-duration: 0.5s;
}
.js_animation_zoomIn.js_isActive.animation-duration_1500 {
  animation-duration: 1.5s;
}
.js_animation_zoomIn.js_isActive.animation-duration_2500 {
  animation-duration: 2.5s;
}

.bliderBox {
  position: relative;
  max-height: 250px;
  overflow: hidden;
  transition: all 0.3s ease;
}
@media screen and (max-width: 375px) {
  .bliderBox {
    max-height: 250px;
  }
}
.bliderBox:before {
  display: block;
  content: "";
  height: 125px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, white 50%);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
@media screen and (max-width: 375px) {
  .bliderBox:before {
    height: 125px;
  }
}

.bliderBox.js_isActive {
  max-height: 100%;
}

.bliderBox_triggerGroup {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
}

.btn_group_center {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: baseline;
}

.btn01 {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
  width: 75%;
  background-color: #F39800;
  border-radius: 5px;
  padding: 0.6em 0;
  margin: 0 auto;
  max-width: 240px;
  line-height: 1.1;
}
@media screen and (min-width: 428px) {
  .btn01 {
    max-width: 270px;
  }
}
.btn01 a {
  display: block;
  height: 100%;
}
.btn01 a:link, .btn01 a:visited, .btn01 a:hover, .btn01 a:active, .btn01 a:focus {
  color: #fff;
  text-decoration: none;
}
.btn01 p {
  font-size: 14px;
  text-align: center;
  color: #fff;
}

.btn02 {
  width: 55%;
  background-color: #595757;
  border-radius: 1em;
  padding: 0.25em 0 0.2em;
  margin: 0 auto;
  max-width: 230px;
}
.btn02 a {
  display: block;
  height: 100%;
}
.btn02 a:link, .btn02 a:visited, .btn02 a:hover, .btn02 a:active, .btn02 a:focus {
  color: #fff;
  text-decoration: none;
}
.btn02 p {
  font-size: 12px;
  text-align: center;
  color: #fff;
}

.form_btn {
  display: block;
  cursor: pointer;
  width: 100%;
  display: block;
  font-family: "Helvetica Neue", "Roboto", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Meiryo, sans-serif;
  font-size: 1rem;
  text-align: center;
  color: #fff;
  padding: 1.2rem;
}
.form_btn input[type=file],
.form_btn input[type=button],
.form_btn button[type=submit] {
  display: none !important;
  appearance: none;
}

.login_btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
  transition: all 0.3s ease;
  font-family: "Helvetica Neue", "Roboto", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Meiryo, sans-serif;
  width: 75%;
  border: 1px solid #F39800;
  border-radius: 5em;
  padding: 0.7em 0 0.6em;
  margin: 0 auto;
  max-width: 240px;
  line-height: 1.1;
  text-align: center;
}
.login_btn:link, .login_btn:visited, .login_btn:hover, .login_btn:active, .login_btn:focus {
  color: #000;
  text-decoration: none;
}
.login_btn:hover {
  opacity: 0.7;
}
@media screen and (min-width: 428px) {
  .login_btn {
    max-width: 270px;
  }
}
.login_btn:hover {
  background-color: #F39800;
  color: #fff;
  border: 1px solid transparent;
}

.box01 {
  border: 1px solid #fff;
  padding: 16px;
}

.wrapper {
  width: 100%;
  max-width: 834px;
  margin: 0 auto;
}

.container {
  margin: 0 auto;
}

.topContainer {
  display: flex;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 10vh;
}
@media screen and (max-height: 630px) {
  .topContainer {
    margin-bottom: 20vh;
  }
}

.container_inner {
  width: 100%;
  max-width: 500px;
  height: auto;
  margin: 0 auto;
}

.container_innerLayout01 {
  padding: clamp(20px, 10vw + 64px, 100px) clamp(20px, 7.5vw, 100px) 100px;
  max-width: none;
}

.container_innerLayout02 {
  padding: clamp(20px, 10vw + 64px, 100px) clamp(20px, 7.5vw, 100px) 100px;
  max-width: none;
}

.wpcf7-spinner {
  display: none !important;
}

.name_filed input[type=text] {
  width: calc(50% - 0.5rem);
}

.codedropz-upload-wrapper {
  display: none;
}

.dnd-upload-status {
  display: none;
}

.mwform-file-delete {
  display: none !important;
}

.mw-wp-form_image {
  display: none !important;
}

.mw_wp_form_confirm .confirm-hidden {
  display: none;
}
.mw_wp_form_confirm .table02 th {
  font-size: 0.75rem;
}
.mw_wp_form_confirm .fileName {
  font-size: 1rem;
}

.mw_wp_form .error {
  padding: 5px 0;
}

.fp-tableCell {
  display: flex;
  align-items: center;
  width: 100%;
}

.header {
  position: fixed;
  z-index: 5000;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 100%;
  max-width: 834px;
  margin: 0 auto;
  padding: 0 20px;
  height: 64px;
  max-height: 64px;
  transition: all 0.3s ease;
  background-color: rgba(255, 255, 255, 0.7);
}
@media screen and (max-width: 375px) {
  .header {
    height: 44px;
    max-height: 44px;
  }
}
.header.js_isHidden {
  transform: translate(0%, -101%);
}

.brand_logo {
  min-width: 153px;
  max-width: 250px;
}
@media screen and (max-width: 375px) {
  .brand_logo {
    min-width: 120px;
  }
}
@media screen and (min-width: 428px) {
  .brand_logo {
    min-width: 200px;
  }
}
.brand_logo a {
  display: block;
  height: 100%;
}
.brand_logo a:link, .brand_logo a:visited, .brand_logo a:hover, .brand_logo a:active, .brand_logo a:focus {
  color: #000;
  text-decoration: none;
}
.brand_logo a svg {
  vertical-align: bottom;
}

.heading01,
.heading02,
.heading03,
.heading04 {
  font-family: "Helvetica Neue", "Roboto", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Meiryo, sans-serif;
  font-weight: bold;
  text-align: center;
}

.heading01 {
  font-size: clamp(48px, 12.75vw, 130px);
}

.small_heading {
  font-size: 0.75em;
  font-weight: bold;
}

.heading02 {
  font-size: 24px;
}

.heading03 {
  display: inline-block;
  position: relative;
  font-size: 16px;
}
@media screen and (min-width: 428px) {
  .heading03 {
    font-size: 20px;
  }
}
.heading03:before, .heading03:after {
  display: inline-block;
  content: "";
  position: absolute;
  top: 50%;
  bottom: 50%;
  width: 50px;
  height: 1px;
  background-color: #000;
}
.heading03:before {
  right: 110%;
}
.heading03:after {
  left: 110%;
}

.heading04 {
  color: #fff;
  font-size: 13px;
  text-align: left;
}

.heading05 {
  font-family: "Helvetica Neue", "Roboto", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Meiryo, sans-serif;
  font-size: 18px;
  border-top: 2px solid #F39800;
  border-bottom: 2px solid #F39800;
  padding: 1rem 0;
  margin-bottom: 2rem;
}

.heading06 {
  font-family: "Helvetica Neue", "Roboto", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Meiryo, sans-serif;
  font-size: 1.2rem;
  color: #000;
  margin-bottom: 1.5rem;
}

.heading_container {
  width: 100%;
  text-align: center;
}

.top_hero {
  width: 100%;
  height: 100vh;
  background-color: #fff;
}

.hero_inner {
  width: 100%;
  max-width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  row-gap: 2em;
}

.hero_lead {
  font-family: "Helvetica Neue", "Roboto", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Meiryo, sans-serif;
  font-size: clamp(23px, 6vw, 62px);
  text-align: center;
}

.hero_image {
  width: 25%;
  margin: 0 auto;
}
.hero_image img {
  width: 100%;
  max-width: 100%;
}

.js_loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.8s, visibility 0.8s;
  z-index: 99999;
}
.js_isLoaded .js_loader {
  visibility: hidden;
  opacity: 0;
}

.js_loader_unit {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}

.js_loading_logo {
  width: 30vw;
  max-width: 300px;
  margin: 0 auto 20px;
}
.js_loading_logo svg {
  fill: #fff;
}

.js_loader_progress {
  width: 100%;
  height: 1px;
}
.js_isLoaded .js_loader_progress {
  visibility: hidden;
  opacity: 0;
}

.js_loader_progress_bar {
  background: #000;
  height: 100%;
  width: 0;
  transition: width 0.4s;
}

.js_loader_progress_number {
  text-align: center;
  font-size: 10px;
  width: 100%;
  margin-top: 10px;
}

.loading_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: #F39800;
}

.loading_logo {
  fill: #3d3a39;
}

.introduction_list {
  display: flex;
  flex-flow: row wrap;
  column-gap: 8px;
  row-gap: 8px;
  align-items: flex-start;
}
.introduction_list dt {
  width: calc(20% - 4px);
  font-size: 10px;
  text-align: center;
  padding: 0.41666667em;
  color: #fff;
  background-color: #9FA0A0;
}
.introduction_list dt:last-of-type {
  padding-right: 0;
  padding-left: 0;
}
.introduction_list dd {
  width: calc(80% - 4px);
  font-size: 10px;
}
.introduction_list dd p {
  font-size: 10px;
  margin-bottom: 8px;
}
@media screen and (min-width: 428px) {
  .introduction_list dd p {
    font-size: 12px;
  }
}
.introduction_list dd p:last-child {
  margin-bottom: 0;
}

.form_caution_list {
  width: 100%;
}
.form_caution_list .form_caution_list_body {
  max-width: 350px;
  margin: 0 auto;
}
.form_caution_list .form_caution_list_body li {
  position: relative;
  padding-left: 12px;
}
.form_caution_list .form_caution_list_body li p {
  font-family: "Helvetica Neue", "Roboto", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Meiryo, sans-serif;
  font-size: 10px;
  font-weight: bold;
}
.form_caution_list .form_caution_list_body li:before {
  position: absolute;
  left: 0;
  top: -10%;
  bottom: 50%;
  content: "●";
  color: #9FA0A0;
  font-size: 20px;
}

.layer_contents {
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: transparent;
  padding-top: 100px;
}

.layer_evelation02 {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  background-color: #EFEFEF;
  width: 100%;
  max-width: 834px;
  height: 100vh;
  overflow: hidden;
  margin: 0 auto;
}
@media screen and (max-width: 428px) {
  .layer_evelation02 {
    height: 100lvh;
  }
}

.layer_evelation01 {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 834px;
  height: 100vh;
  overflow: hidden;
  margin: 0 auto;
  background-color: #FAC02C;
}
@media screen and (max-width: 428px) {
  .layer_evelation01 {
    height: 100lvh;
  }
}
.layer_evelation01:before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-100%, -100%) rotate(45deg);
  width: 150%;
  height: 150%;
  background-color: #F39800;
  transition: all 1s ease;
}
@media screen and (min-width: 428px) {
  .layer_evelation01:before {
    width: 130%;
  }
}
.layer_evelation01.js_isActive:before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.modal {
  z-index: 31000;
}

.modal-backdrop.show {
  z-index: 30999;
}

.modal-backdrop.show {
  background: rgba(0, 0, 0, 0.7);
}

.modal-dialog {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 80%;
  min-height: 100vh;
  margin: 0 auto;
}

.modal_content {
  padding: 30px;
}

.offcanvasTrigger {
  z-index: 7999;
  position: fixed;
  top: 10px;
  right: 10px;
}

.js_isOffcanvasActive {
  max-width: 100%;
}

@media screen and (max-width: 1024px) {
  .offcanvasMenu {
    width: 70%;
    background: #fff;
    transition: all 0.3s ease;
    display: block;
  }
}

@media screen and (max-width: 1024px) {
  .offcanvasMenu {
    z-index: 7998;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 100vh;
    transform: translate(-100%, 0);
  }
}

.js_isOffcanvasActive .offcanvasMenu {
  transform: translate(0%, 0);
}

@media screen and (max-width: 1024px) {
  .offcanvasBg {
    background: rgba(0, 0, 0, 0.7);
    transition: all 0.4s ease;
  }
}

@media screen and (max-width: 1024px) {
  .offcanvasBg {
    z-index: 7997;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    transform: translate(-100%, 0);
  }
}

.js_isOffcanvasActive .offcanvasBg {
  transform: translate(0%, 0);
}

.pagetop {
  position: fixed;
  right: 15px;
  bottom: 20px;
  transform: translate(0%, 200%);
}

.pagetop.js_isFixed {
  transform: translate(0%, -100%);
  bottom: 0;
}

.pagetop.js_isHold {
  z-index: 9999;
  position: absolute;
  top: 0;
  bottom: auto;
  transform: translate(0%, -50%);
}

.pagetop {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  background: #F39800;
  border-radius: 200px;
  transition: all 0.3s ease;
}
.pagetop:hover {
  background: #2c0851;
  cursor: pointer;
}

.pagetop svg {
  width: 50%;
}

.pagetop_icon_svg {
  fill: #fff;
}

.sg_container_wrapper {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 100px 100px 0 100px;
}
@media screen and (max-width: 980px) {
  .sg_container_wrapper {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.sg_container {
  width: calc(100% - 350px);
}
.sg_container_inner {
  margin: 0 auto;
}
.sg_container_inner > *:first-child {
  margin-top: 0 !important;
}
@media screen and (max-width: 980px) {
  .sg_container {
    width: 100%;
  }
}

.sg_side_container {
  width: 280px;
  min-height: 500px;
  border-right: 1px solid #ccc;
}
@media screen and (max-width: 980px) {
  .sg_side_container {
    display: none;
  }
}

.sg_nav_body {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  list-style: none;
  margin: 0 100px 0 auto;
  padding: 0;
}
.sg_nav_body li {
  display: block;
  width: 100%;
  padding: 5px 10px;
}
.sg_nav_body li a {
  display: block;
  padding: 0.5em 0;
}
.sg_nav_body li a:active, .sg_nav_body li a:link, .sg_nav_body li a:visited, .sg_nav_body li a:focus, .sg_nav_body li a:hover {
  color: #333;
  text-decoration: none;
}

.sg_header {
  background: #eee;
  height: 80px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
}
.sg_header_inner {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 100px;
  width: 100%;
  max-width: 100%;
}
@media screen and (max-width: 980px) {
  .sg_header_inner {
    padding: 0 10px;
  }
}

.sg_nav {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  list-style: none;
  margin-left: auto;
  padding: 0;
}
.sg_nav li a {
  display: block;
  padding: 0.25em 1em;
}
@media screen and (max-width: 980px) {
  .sg_nav {
    margin-left: 0;
  }
}

.sg_mv {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  background: #ccc;
}

.sg_mv_inner {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.sg_mv_title {
  width: 100%;
  margin: 0 auto 0.5em;
  font-size: 1.5em;
  text-align: center;
  line-height: 1.4;
}

.sg_mv_lead {
  width: 100%;
  text-align: center;
  font-size: 0.8em;
}

.sg_footer {
  position: relative;
  background: #eee;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.sg_copy {
  font-size: 11px;
  line-height: 1;
  padding: 1em;
}

.sg_heading01 {
  font-size: 2.5em;
  margin: 2em 0 2em;
}

.sg_heading02 {
  font-size: 2.2em;
  margin: 2em 0 1em;
  padding-bottom: 0.5em;
  border-bottom: solid 5px #ccc;
}

.sg_heading04 {
  font-size: 1.5em;
  margin: 2em 0 1em;
}

.sg_btn01 {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
  display: inline-block;
  border: solid 1px #0028b7;
  padding: 0.5em 5em 0.6em;
  border-radius: 5em;
  font-size: 1em;
  color: #0028b7;
  background-color: #ffffff;
}

.sg_code_inline {
  display: inline-block;
  border: solid 1px #ec007a;
  background: #ffecf8;
  color: #ec007a;
  border-radius: 5px;
  font-size: 0.8em;
  padding: 0.27em 0.5em 0.2em;
  line-height: 1.1;
}

.sg_path_inline {
  display: inline-block;
  border: solid 1px #ccc;
  background: #f6f6f6;
  color: #333;
  border-radius: 5px;
  margin-left: 0.5em;
  font-size: 0.8em;
  padding: 0.27em 0.5em 0.2em;
  line-height: 1.1;
}

.sg_section_unit {
  margin-bottom: 10em;
}

.sg_demo_unit {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 10px;
  border: solid 1px #ccc;
  padding: 20px;
  margin-bottom: 2em;
}
.sg_demo_unit:before {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  content: "DEMO";
  background: #fff;
  padding: 0.3em 0.5em;
  font-size: 10px;
  line-height: 1.1;
  border-left: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
  border-radius: 0 10px 0 0;
}
.sg_textUnit {
  margin-bottom: 2em;
}
.sg_textUnit > p {
  margin-bottom: 1em;
  line-height: 1.6em;
}
.sg_textUnit > p:last-child {
  margin-bottom: 0;
}

.textUnit {
  margin-bottom: 2rem;
}

.thanks_textUnit p {
  color: #fff;
}

.textUnit.isColorWhite p {
  color: #fff;
}

.annotation p {
  font-size: 12px;
}

.table01 {
  text-align: left;
  color: #fff;
}
.table01 thead tr th {
  padding-bottom: 12px;
}
.table01 tbody tr {
  display: block;
  margin-bottom: 12px;
}
.table01 tbody tr:last-child {
  margin-bottom: 0;
}
.table01 th {
  font-weight: bold;
}
.table01 td {
  padding-left: 20px;
}

.table02 {
  display: block;
  border: 1px solid #FAC02C;
}
.table02 thead, .table02 tbody, .table02 tr, .table02 th, .table02 td {
  display: block;
  width: 100%;
}
.table02 tr {
  border-bottom: 1px solid #FAC02C;
  padding: 1.5rem 2rem;
}
.table02 th {
  font-family: "Helvetica Neue", "Roboto", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Meiryo, sans-serif;
  font-size: 1.2rem;
  text-align: left;
}

.global_ui {
  position: fixed;
  z-index: 5000;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 834px;
  max-height: 80px;
  margin: 0 auto;
  padding: 8px 0;
  background-color: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
}
.global_ui.js_isHidden {
  transform: translate(0%, 101%);
}

.global_ui_body {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}

.global_ui_item {
  position: relative;
  width: 50%;
  padding: 6px 0;
}
@media screen and (max-width: 375px) {
  .global_ui_item {
    padding: 0;
  }
}
.global_ui_item:last-child .ui_icon img {
  width: 24px;
}
.global_ui_item:before {
  position: absolute;
  top: 0;
  right: -1px;
  bottom: 0;
  content: "";
  height: 100%;
  width: 2px;
  border-radius: 3px;
  background-color: #000;
}
.global_ui_item:last-child:before {
  display: none;
}
.global_ui_item a {
  transition: all 0.3s ease;
}
.global_ui_item a:link, .global_ui_item a:visited, .global_ui_item a:hover, .global_ui_item a:active, .global_ui_item a:focus {
  color: #000;
  text-decoration: none;
}
.global_ui_item a:hover {
  opacity: 0.7;
}
.global_ui_item .ui_icon {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  text-align: center;
}
.global_ui_item .ui_icon img {
  width: 30px;
  max-width: 100%;
  padding-bottom: 4px;
}
.global_ui_item .ui_icon .ui_icon_figcaption {
  text-decoration: none;
  font-family: "Helvetica Neue", "Roboto", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Meiryo, sans-serif;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
}
@media screen and (max-width: 375px) {
  .global_ui_item .ui_icon .ui_icon_figcaption {
    font-size: 11px;
  }
}

.formUnit {
  background-color: #fff;
}

.form_title {
  width: 100%;
  font-size: 20px;
  text-align: center;
  color: #000;
  background-color: #FAC02C;
  padding: 0.6153846154rem 0.9230769231rem;
}

.required {
  color: #EA5514;
  font-size: 0.65em;
  font-weight: normal;
  margin-left: 0.5em;
}

.fileName {
  font-family: "Helvetica Neue", "Roboto", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Meiryo, sans-serif;
  font-size: 0.8rem;
  color: #333;
  font-weight: bold;
}

.fileName_lead {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.name_filed {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1rem;
}
.name_filed input[type=text] {
  max-width: 100%;
  border: none;
  border-radius: 5px;
  background-color: rgba(250, 192, 44, 0.4);
  font-size: 16px;
  padding: 1rem 0.9rem;
}
.name_filed input[type=text]::placeholder {
  color: #7C7C7C;
}

.email_filed input[type=email] {
  width: 100%;
  border: none;
  border-radius: 5px;
  background-color: rgba(250, 192, 44, 0.4);
  font-size: 16px;
  padding: 1rem 0.9rem;
}
.email_filed input[type=email]::placeholder {
  color: #7C7C7C;
}

.submit_container {
  width: 100%;
  max-width: 100%;
  background-color: #EFEFEF;
  padding-top: 12px;
  padding-bottom: 24px;
}

.js-upload-filename01,
.js-upload-filename02 {
  color: #9FA0A0;
}
.js-upload-filename01.js_isActive,
.js-upload-filename02.js_isActive {
  color: #000;
}

.top_introduction_inner {
  width: 100%;
  max-width: 500px;
  background-color: #fff;
  padding: 1.8461538462rem 2rem;
  margin: 0 auto;
}
@media screen and (max-width: 375px) {
  .top_introduction_inner {
    padding: 1.5384615385rem 1rem;
  }
}

.introduction_heading_container {
  width: 100%;
  text-align: center;
}

.top_introduction_heading {
  position: relative;
  display: inline-block;
  font-size: 25px;
}
@media screen and (min-width: 834px) {
  .top_introduction_heading {
    font-size: 30px;
  }
}
.top_introduction_heading:before {
  position: absolute;
  left: -1.85em;
  bottom: -5px;
  width: 1.5em;
  display: block;
  content: url("../img/top/hand_img.svg");
}

.top_operating_company_inner {
  width: 100%;
  max-width: 500px;
  background-color: transparent;
  padding: 36px 0;
  margin: 0 auto;
}

.operating_company_textUnit {
  padding: 0;
}

.company_img {
  width: 90%;
  max-width: 400px;
  margin: 0 auto;
}
@media screen and (max-width: 375px) {
  .company_img {
    width: 72%;
  }
}

#brand_logo02 {
  fill: #fff;
}

.copyright_group {
  width: 100%;
  max-width: 100%;
}

.brand_logo_footer {
  width: 100%;
  text-align: center;
}

.brand_logo_lighter {
  width: 45%;
  margin: 0 auto;
  text-align: center;
}
@media screen and (min-width: 834px) {
  .brand_logo_lighter {
    width: 25%;
  }
}

.copyright {
  text-align: center;
  font-family: "Helvetica Neue", "Roboto", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Meiryo, sans-serif;
  font-size: 10px;
  color: #fff;
}
@media screen and (min-width: 834px) {
  .copyright {
    font-size: 12px;
  }
}

.thanks_heading {
  font-family: "Helvetica Neue", "Roboto", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", Meiryo, sans-serif;
  font-size: 1.4rem;
  text-align: center;
  color: #fff;
}

.privacy_container {
  width: 100%;
  margin: 0 auto;
  padding: 88px 20px 100px 20px;
}

.privacy_inner {
  width: 100%;
  max-width: 100%;
  background-color: #fff;
  padding: 2rem 1.5rem;
}

.privacyUnit {
  margin-bottom: 1rem;
}
.privacyUnit:last-child {
  margin-bottom: 0;
}

.privacy_list01 {
  list-style-type: lower-latin;
  padding-left: 2rem;
  margin-bottom: 0.75rem;
}
.privacy_list01 li {
  margin-bottom: 1rem;
  line-height: 1.4;
}
.privacy_list01 li:last-child {
  margin-bottom: 0;
}
.privacy_list01 ol {
  list-style-type: lower-roman;
  padding-left: 1rem;
}
.privacy_list01 ol ol {
  list-style-type: lower-roman;
  padding-left: 1rem;
}

.privacy_list02 {
  list-style-type: disc;
  padding-left: 2rem;
  margin-bottom: 0.75rem;
}
.privacy_list02 li {
  margin-bottom: 1rem;
  line-height: 1.4;
}
.privacy_list02 li:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 1980px) {
  .hidden_xlg {
    display: none !important;
  }
}

@media screen and (max-width: 1366px) {
  .hidden_lg {
    display: none !important;
  }
}

@media screen and (max-width: 1024px) {
  .hidden_mlg {
    display: none !important;
  }
}

@media screen and (max-width: 980px) {
  .hidden_slg {
    display: none !important;
  }
}

@media screen and (max-width: 834px) {
  .hidden_md {
    display: none !important;
  }
}

@media screen and (max-width: 667px) {
  .hidden_6ls {
    display: none !important;
  }
}

@media screen and (max-width: 568px) {
  .hidden_5ls {
    display: none !important;
  }
}

@media screen and (max-width: 428px) {
  .hidden_sm {
    display: none !important;
  }
}

@media screen and (max-width: 375px) {
  .hidden_msm {
    display: none !important;
  }
}

@media screen and (max-width: 320px) {
  .hidden_xs {
    display: none !important;
  }
}

.visible_xlg {
  display: none !important;
}
@media screen and (max-width: 1980px) {
  .visible_xlg {
    display: block !important;
  }
}

.visible_lg {
  display: none !important;
}
@media screen and (max-width: 1366px) {
  .visible_lg {
    display: block !important;
  }
}

.visible_mlg {
  display: none !important;
}
@media screen and (max-width: 1024px) {
  .visible_mlg {
    display: block !important;
  }
}

.visible_slg {
  display: none !important;
}
@media screen and (max-width: 980px) {
  .visible_slg {
    display: block !important;
  }
}

.visible_md {
  display: none !important;
}
@media screen and (max-width: 834px) {
  .visible_md {
    display: block !important;
  }
}

.visible_6ls {
  display: none !important;
}
@media screen and (max-width: 667px) {
  .visible_6ls {
    display: block !important;
  }
}

.visible_5ls {
  display: none !important;
}
@media screen and (max-width: 568px) {
  .visible_5ls {
    display: block !important;
  }
}

.visible_sm {
  display: none !important;
}
@media screen and (max-width: 428px) {
  .visible_sm {
    display: block !important;
  }
}

.visible_msm {
  display: none !important;
}
@media screen and (max-width: 375px) {
  .visible_msm {
    display: block !important;
  }
}

.visible_xs {
  display: none !important;
}
@media screen and (max-width: 320px) {
  .visible_xs {
    display: block !important;
  }
}

.dis_block {
  display: block;
}

.dis_inlineBlock {
  display: inline-block;
}

.dis_flex {
  display: flex;
}

.fz_0 {
  font-size: 0px !important;
}

@media screen and (max-width: 1980px) {
  .fz_0_xlg {
    font-size: 0px !important;
  }
}
@media screen and (max-width: 1366px) {
  .fz_0_lg {
    font-size: 0px !important;
  }
}
@media screen and (max-width: 1024px) {
  .fz_0_mlg {
    font-size: 0px !important;
  }
}
@media screen and (max-width: 980px) {
  .fz_0_xlg {
    font-size: 0px !important;
  }
}
@media screen and (max-width: 834px) {
  .fz_0_md {
    font-size: 0px !important;
  }
}
@media screen and (max-width: 667px) {
  .fz_0_6ls {
    font-size: 0px !important;
  }
}
@media screen and (max-width: 568px) {
  .fz_0_5ls {
    font-sizxxe: 0px !important;
  }
}
@media screen and (max-width: 428px) {
  .fz_0_sm {
    font-size: 0px !important;
  }
}
@media screen and (max-width: 375px) {
  .fz_0_msm {
    font-size: 0px !important;
  }
}
@media screen and (max-width: 320px) {
  .fz_0_xs {
    font-size: 0px !important;
  }
}
.fz_1 {
  font-size: 1px !important;
}

@media screen and (max-width: 1980px) {
  .fz_1_xlg {
    font-size: 1px !important;
  }
}
@media screen and (max-width: 1366px) {
  .fz_1_lg {
    font-size: 1px !important;
  }
}
@media screen and (max-width: 1024px) {
  .fz_1_mlg {
    font-size: 1px !important;
  }
}
@media screen and (max-width: 980px) {
  .fz_1_xlg {
    font-size: 1px !important;
  }
}
@media screen and (max-width: 834px) {
  .fz_1_md {
    font-size: 1px !important;
  }
}
@media screen and (max-width: 667px) {
  .fz_1_6ls {
    font-size: 1px !important;
  }
}
@media screen and (max-width: 568px) {
  .fz_1_5ls {
    font-sizxxe: 1px !important;
  }
}
@media screen and (max-width: 428px) {
  .fz_1_sm {
    font-size: 1px !important;
  }
}
@media screen and (max-width: 375px) {
  .fz_1_msm {
    font-size: 1px !important;
  }
}
@media screen and (max-width: 320px) {
  .fz_1_xs {
    font-size: 1px !important;
  }
}
.fz_2 {
  font-size: 2px !important;
}

@media screen and (max-width: 1980px) {
  .fz_2_xlg {
    font-size: 2px !important;
  }
}
@media screen and (max-width: 1366px) {
  .fz_2_lg {
    font-size: 2px !important;
  }
}
@media screen and (max-width: 1024px) {
  .fz_2_mlg {
    font-size: 2px !important;
  }
}
@media screen and (max-width: 980px) {
  .fz_2_xlg {
    font-size: 2px !important;
  }
}
@media screen and (max-width: 834px) {
  .fz_2_md {
    font-size: 2px !important;
  }
}
@media screen and (max-width: 667px) {
  .fz_2_6ls {
    font-size: 2px !important;
  }
}
@media screen and (max-width: 568px) {
  .fz_2_5ls {
    font-sizxxe: 2px !important;
  }
}
@media screen and (max-width: 428px) {
  .fz_2_sm {
    font-size: 2px !important;
  }
}
@media screen and (max-width: 375px) {
  .fz_2_msm {
    font-size: 2px !important;
  }
}
@media screen and (max-width: 320px) {
  .fz_2_xs {
    font-size: 2px !important;
  }
}
.fz_3 {
  font-size: 3px !important;
}

@media screen and (max-width: 1980px) {
  .fz_3_xlg {
    font-size: 3px !important;
  }
}
@media screen and (max-width: 1366px) {
  .fz_3_lg {
    font-size: 3px !important;
  }
}
@media screen and (max-width: 1024px) {
  .fz_3_mlg {
    font-size: 3px !important;
  }
}
@media screen and (max-width: 980px) {
  .fz_3_xlg {
    font-size: 3px !important;
  }
}
@media screen and (max-width: 834px) {
  .fz_3_md {
    font-size: 3px !important;
  }
}
@media screen and (max-width: 667px) {
  .fz_3_6ls {
    font-size: 3px !important;
  }
}
@media screen and (max-width: 568px) {
  .fz_3_5ls {
    font-sizxxe: 3px !important;
  }
}
@media screen and (max-width: 428px) {
  .fz_3_sm {
    font-size: 3px !important;
  }
}
@media screen and (max-width: 375px) {
  .fz_3_msm {
    font-size: 3px !important;
  }
}
@media screen and (max-width: 320px) {
  .fz_3_xs {
    font-size: 3px !important;
  }
}
.fz_4 {
  font-size: 4px !important;
}

@media screen and (max-width: 1980px) {
  .fz_4_xlg {
    font-size: 4px !important;
  }
}
@media screen and (max-width: 1366px) {
  .fz_4_lg {
    font-size: 4px !important;
  }
}
@media screen and (max-width: 1024px) {
  .fz_4_mlg {
    font-size: 4px !important;
  }
}
@media screen and (max-width: 980px) {
  .fz_4_xlg {
    font-size: 4px !important;
  }
}
@media screen and (max-width: 834px) {
  .fz_4_md {
    font-size: 4px !important;
  }
}
@media screen and (max-width: 667px) {
  .fz_4_6ls {
    font-size: 4px !important;
  }
}
@media screen and (max-width: 568px) {
  .fz_4_5ls {
    font-sizxxe: 4px !important;
  }
}
@media screen and (max-width: 428px) {
  .fz_4_sm {
    font-size: 4px !important;
  }
}
@media screen and (max-width: 375px) {
  .fz_4_msm {
    font-size: 4px !important;
  }
}
@media screen and (max-width: 320px) {
  .fz_4_xs {
    font-size: 4px !important;
  }
}
.fz_5 {
  font-size: 5px !important;
}

@media screen and (max-width: 1980px) {
  .fz_5_xlg {
    font-size: 5px !important;
  }
}
@media screen and (max-width: 1366px) {
  .fz_5_lg {
    font-size: 5px !important;
  }
}
@media screen and (max-width: 1024px) {
  .fz_5_mlg {
    font-size: 5px !important;
  }
}
@media screen and (max-width: 980px) {
  .fz_5_xlg {
    font-size: 5px !important;
  }
}
@media screen and (max-width: 834px) {
  .fz_5_md {
    font-size: 5px !important;
  }
}
@media screen and (max-width: 667px) {
  .fz_5_6ls {
    font-size: 5px !important;
  }
}
@media screen and (max-width: 568px) {
  .fz_5_5ls {
    font-sizxxe: 5px !important;
  }
}
@media screen and (max-width: 428px) {
  .fz_5_sm {
    font-size: 5px !important;
  }
}
@media screen and (max-width: 375px) {
  .fz_5_msm {
    font-size: 5px !important;
  }
}
@media screen and (max-width: 320px) {
  .fz_5_xs {
    font-size: 5px !important;
  }
}
.fz_6 {
  font-size: 6px !important;
}

@media screen and (max-width: 1980px) {
  .fz_6_xlg {
    font-size: 6px !important;
  }
}
@media screen and (max-width: 1366px) {
  .fz_6_lg {
    font-size: 6px !important;
  }
}
@media screen and (max-width: 1024px) {
  .fz_6_mlg {
    font-size: 6px !important;
  }
}
@media screen and (max-width: 980px) {
  .fz_6_xlg {
    font-size: 6px !important;
  }
}
@media screen and (max-width: 834px) {
  .fz_6_md {
    font-size: 6px !important;
  }
}
@media screen and (max-width: 667px) {
  .fz_6_6ls {
    font-size: 6px !important;
  }
}
@media screen and (max-width: 568px) {
  .fz_6_5ls {
    font-sizxxe: 6px !important;
  }
}
@media screen and (max-width: 428px) {
  .fz_6_sm {
    font-size: 6px !important;
  }
}
@media screen and (max-width: 375px) {
  .fz_6_msm {
    font-size: 6px !important;
  }
}
@media screen and (max-width: 320px) {
  .fz_6_xs {
    font-size: 6px !important;
  }
}
.fz_7 {
  font-size: 7px !important;
}

@media screen and (max-width: 1980px) {
  .fz_7_xlg {
    font-size: 7px !important;
  }
}
@media screen and (max-width: 1366px) {
  .fz_7_lg {
    font-size: 7px !important;
  }
}
@media screen and (max-width: 1024px) {
  .fz_7_mlg {
    font-size: 7px !important;
  }
}
@media screen and (max-width: 980px) {
  .fz_7_xlg {
    font-size: 7px !important;
  }
}
@media screen and (max-width: 834px) {
  .fz_7_md {
    font-size: 7px !important;
  }
}
@media screen and (max-width: 667px) {
  .fz_7_6ls {
    font-size: 7px !important;
  }
}
@media screen and (max-width: 568px) {
  .fz_7_5ls {
    font-sizxxe: 7px !important;
  }
}
@media screen and (max-width: 428px) {
  .fz_7_sm {
    font-size: 7px !important;
  }
}
@media screen and (max-width: 375px) {
  .fz_7_msm {
    font-size: 7px !important;
  }
}
@media screen and (max-width: 320px) {
  .fz_7_xs {
    font-size: 7px !important;
  }
}
.fz_8 {
  font-size: 8px !important;
}

@media screen and (max-width: 1980px) {
  .fz_8_xlg {
    font-size: 8px !important;
  }
}
@media screen and (max-width: 1366px) {
  .fz_8_lg {
    font-size: 8px !important;
  }
}
@media screen and (max-width: 1024px) {
  .fz_8_mlg {
    font-size: 8px !important;
  }
}
@media screen and (max-width: 980px) {
  .fz_8_xlg {
    font-size: 8px !important;
  }
}
@media screen and (max-width: 834px) {
  .fz_8_md {
    font-size: 8px !important;
  }
}
@media screen and (max-width: 667px) {
  .fz_8_6ls {
    font-size: 8px !important;
  }
}
@media screen and (max-width: 568px) {
  .fz_8_5ls {
    font-sizxxe: 8px !important;
  }
}
@media screen and (max-width: 428px) {
  .fz_8_sm {
    font-size: 8px !important;
  }
}
@media screen and (max-width: 375px) {
  .fz_8_msm {
    font-size: 8px !important;
  }
}
@media screen and (max-width: 320px) {
  .fz_8_xs {
    font-size: 8px !important;
  }
}
.fz_9 {
  font-size: 9px !important;
}

@media screen and (max-width: 1980px) {
  .fz_9_xlg {
    font-size: 9px !important;
  }
}
@media screen and (max-width: 1366px) {
  .fz_9_lg {
    font-size: 9px !important;
  }
}
@media screen and (max-width: 1024px) {
  .fz_9_mlg {
    font-size: 9px !important;
  }
}
@media screen and (max-width: 980px) {
  .fz_9_xlg {
    font-size: 9px !important;
  }
}
@media screen and (max-width: 834px) {
  .fz_9_md {
    font-size: 9px !important;
  }
}
@media screen and (max-width: 667px) {
  .fz_9_6ls {
    font-size: 9px !important;
  }
}
@media screen and (max-width: 568px) {
  .fz_9_5ls {
    font-sizxxe: 9px !important;
  }
}
@media screen and (max-width: 428px) {
  .fz_9_sm {
    font-size: 9px !important;
  }
}
@media screen and (max-width: 375px) {
  .fz_9_msm {
    font-size: 9px !important;
  }
}
@media screen and (max-width: 320px) {
  .fz_9_xs {
    font-size: 9px !important;
  }
}
.fz_10 {
  font-size: 10px !important;
}

@media screen and (max-width: 1980px) {
  .fz_10_xlg {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 1366px) {
  .fz_10_lg {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 1024px) {
  .fz_10_mlg {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 980px) {
  .fz_10_xlg {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 834px) {
  .fz_10_md {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 667px) {
  .fz_10_6ls {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 568px) {
  .fz_10_5ls {
    font-sizxxe: 10px !important;
  }
}
@media screen and (max-width: 428px) {
  .fz_10_sm {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 375px) {
  .fz_10_msm {
    font-size: 10px !important;
  }
}
@media screen and (max-width: 320px) {
  .fz_10_xs {
    font-size: 10px !important;
  }
}
.fz_11 {
  font-size: 11px !important;
}

@media screen and (max-width: 1980px) {
  .fz_11_xlg {
    font-size: 11px !important;
  }
}
@media screen and (max-width: 1366px) {
  .fz_11_lg {
    font-size: 11px !important;
  }
}
@media screen and (max-width: 1024px) {
  .fz_11_mlg {
    font-size: 11px !important;
  }
}
@media screen and (max-width: 980px) {
  .fz_11_xlg {
    font-size: 11px !important;
  }
}
@media screen and (max-width: 834px) {
  .fz_11_md {
    font-size: 11px !important;
  }
}
@media screen and (max-width: 667px) {
  .fz_11_6ls {
    font-size: 11px !important;
  }
}
@media screen and (max-width: 568px) {
  .fz_11_5ls {
    font-sizxxe: 11px !important;
  }
}
@media screen and (max-width: 428px) {
  .fz_11_sm {
    font-size: 11px !important;
  }
}
@media screen and (max-width: 375px) {
  .fz_11_msm {
    font-size: 11px !important;
  }
}
@media screen and (max-width: 320px) {
  .fz_11_xs {
    font-size: 11px !important;
  }
}
.fz_12 {
  font-size: 12px !important;
}

@media screen and (max-width: 1980px) {
  .fz_12_xlg {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 1366px) {
  .fz_12_lg {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 1024px) {
  .fz_12_mlg {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 980px) {
  .fz_12_xlg {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 834px) {
  .fz_12_md {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 667px) {
  .fz_12_6ls {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 568px) {
  .fz_12_5ls {
    font-sizxxe: 12px !important;
  }
}
@media screen and (max-width: 428px) {
  .fz_12_sm {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 375px) {
  .fz_12_msm {
    font-size: 12px !important;
  }
}
@media screen and (max-width: 320px) {
  .fz_12_xs {
    font-size: 12px !important;
  }
}
.fz_13 {
  font-size: 13px !important;
}

@media screen and (max-width: 1980px) {
  .fz_13_xlg {
    font-size: 13px !important;
  }
}
@media screen and (max-width: 1366px) {
  .fz_13_lg {
    font-size: 13px !important;
  }
}
@media screen and (max-width: 1024px) {
  .fz_13_mlg {
    font-size: 13px !important;
  }
}
@media screen and (max-width: 980px) {
  .fz_13_xlg {
    font-size: 13px !important;
  }
}
@media screen and (max-width: 834px) {
  .fz_13_md {
    font-size: 13px !important;
  }
}
@media screen and (max-width: 667px) {
  .fz_13_6ls {
    font-size: 13px !important;
  }
}
@media screen and (max-width: 568px) {
  .fz_13_5ls {
    font-sizxxe: 13px !important;
  }
}
@media screen and (max-width: 428px) {
  .fz_13_sm {
    font-size: 13px !important;
  }
}
@media screen and (max-width: 375px) {
  .fz_13_msm {
    font-size: 13px !important;
  }
}
@media screen and (max-width: 320px) {
  .fz_13_xs {
    font-size: 13px !important;
  }
}
.fz_14 {
  font-size: 14px !important;
}

@media screen and (max-width: 1980px) {
  .fz_14_xlg {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 1366px) {
  .fz_14_lg {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 1024px) {
  .fz_14_mlg {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 980px) {
  .fz_14_xlg {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 834px) {
  .fz_14_md {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 667px) {
  .fz_14_6ls {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 568px) {
  .fz_14_5ls {
    font-sizxxe: 14px !important;
  }
}
@media screen and (max-width: 428px) {
  .fz_14_sm {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 375px) {
  .fz_14_msm {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 320px) {
  .fz_14_xs {
    font-size: 14px !important;
  }
}
.fz_15 {
  font-size: 15px !important;
}

@media screen and (max-width: 1980px) {
  .fz_15_xlg {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 1366px) {
  .fz_15_lg {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 1024px) {
  .fz_15_mlg {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 980px) {
  .fz_15_xlg {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 834px) {
  .fz_15_md {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 667px) {
  .fz_15_6ls {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 568px) {
  .fz_15_5ls {
    font-sizxxe: 15px !important;
  }
}
@media screen and (max-width: 428px) {
  .fz_15_sm {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 375px) {
  .fz_15_msm {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 320px) {
  .fz_15_xs {
    font-size: 15px !important;
  }
}
.fz_16 {
  font-size: 16px !important;
}

@media screen and (max-width: 1980px) {
  .fz_16_xlg {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 1366px) {
  .fz_16_lg {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 1024px) {
  .fz_16_mlg {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 980px) {
  .fz_16_xlg {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 834px) {
  .fz_16_md {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 667px) {
  .fz_16_6ls {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 568px) {
  .fz_16_5ls {
    font-sizxxe: 16px !important;
  }
}
@media screen and (max-width: 428px) {
  .fz_16_sm {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 375px) {
  .fz_16_msm {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 320px) {
  .fz_16_xs {
    font-size: 16px !important;
  }
}
.fz_17 {
  font-size: 17px !important;
}

@media screen and (max-width: 1980px) {
  .fz_17_xlg {
    font-size: 17px !important;
  }
}
@media screen and (max-width: 1366px) {
  .fz_17_lg {
    font-size: 17px !important;
  }
}
@media screen and (max-width: 1024px) {
  .fz_17_mlg {
    font-size: 17px !important;
  }
}
@media screen and (max-width: 980px) {
  .fz_17_xlg {
    font-size: 17px !important;
  }
}
@media screen and (max-width: 834px) {
  .fz_17_md {
    font-size: 17px !important;
  }
}
@media screen and (max-width: 667px) {
  .fz_17_6ls {
    font-size: 17px !important;
  }
}
@media screen and (max-width: 568px) {
  .fz_17_5ls {
    font-sizxxe: 17px !important;
  }
}
@media screen and (max-width: 428px) {
  .fz_17_sm {
    font-size: 17px !important;
  }
}
@media screen and (max-width: 375px) {
  .fz_17_msm {
    font-size: 17px !important;
  }
}
@media screen and (max-width: 320px) {
  .fz_17_xs {
    font-size: 17px !important;
  }
}
.fz_18 {
  font-size: 18px !important;
}

@media screen and (max-width: 1980px) {
  .fz_18_xlg {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 1366px) {
  .fz_18_lg {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 1024px) {
  .fz_18_mlg {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 980px) {
  .fz_18_xlg {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 834px) {
  .fz_18_md {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 667px) {
  .fz_18_6ls {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 568px) {
  .fz_18_5ls {
    font-sizxxe: 18px !important;
  }
}
@media screen and (max-width: 428px) {
  .fz_18_sm {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 375px) {
  .fz_18_msm {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 320px) {
  .fz_18_xs {
    font-size: 18px !important;
  }
}
.fz_19 {
  font-size: 19px !important;
}

@media screen and (max-width: 1980px) {
  .fz_19_xlg {
    font-size: 19px !important;
  }
}
@media screen and (max-width: 1366px) {
  .fz_19_lg {
    font-size: 19px !important;
  }
}
@media screen and (max-width: 1024px) {
  .fz_19_mlg {
    font-size: 19px !important;
  }
}
@media screen and (max-width: 980px) {
  .fz_19_xlg {
    font-size: 19px !important;
  }
}
@media screen and (max-width: 834px) {
  .fz_19_md {
    font-size: 19px !important;
  }
}
@media screen and (max-width: 667px) {
  .fz_19_6ls {
    font-size: 19px !important;
  }
}
@media screen and (max-width: 568px) {
  .fz_19_5ls {
    font-sizxxe: 19px !important;
  }
}
@media screen and (max-width: 428px) {
  .fz_19_sm {
    font-size: 19px !important;
  }
}
@media screen and (max-width: 375px) {
  .fz_19_msm {
    font-size: 19px !important;
  }
}
@media screen and (max-width: 320px) {
  .fz_19_xs {
    font-size: 19px !important;
  }
}
.fz_20 {
  font-size: 20px !important;
}

@media screen and (max-width: 1980px) {
  .fz_20_xlg {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 1366px) {
  .fz_20_lg {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 1024px) {
  .fz_20_mlg {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 980px) {
  .fz_20_xlg {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 834px) {
  .fz_20_md {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 667px) {
  .fz_20_6ls {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 568px) {
  .fz_20_5ls {
    font-sizxxe: 20px !important;
  }
}
@media screen and (max-width: 428px) {
  .fz_20_sm {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 375px) {
  .fz_20_msm {
    font-size: 20px !important;
  }
}
@media screen and (max-width: 320px) {
  .fz_20_xs {
    font-size: 20px !important;
  }
}
.fz_21 {
  font-size: 21px !important;
}

@media screen and (max-width: 1980px) {
  .fz_21_xlg {
    font-size: 21px !important;
  }
}
@media screen and (max-width: 1366px) {
  .fz_21_lg {
    font-size: 21px !important;
  }
}
@media screen and (max-width: 1024px) {
  .fz_21_mlg {
    font-size: 21px !important;
  }
}
@media screen and (max-width: 980px) {
  .fz_21_xlg {
    font-size: 21px !important;
  }
}
@media screen and (max-width: 834px) {
  .fz_21_md {
    font-size: 21px !important;
  }
}
@media screen and (max-width: 667px) {
  .fz_21_6ls {
    font-size: 21px !important;
  }
}
@media screen and (max-width: 568px) {
  .fz_21_5ls {
    font-sizxxe: 21px !important;
  }
}
@media screen and (max-width: 428px) {
  .fz_21_sm {
    font-size: 21px !important;
  }
}
@media screen and (max-width: 375px) {
  .fz_21_msm {
    font-size: 21px !important;
  }
}
@media screen and (max-width: 320px) {
  .fz_21_xs {
    font-size: 21px !important;
  }
}
.fz_22 {
  font-size: 22px !important;
}

@media screen and (max-width: 1980px) {
  .fz_22_xlg {
    font-size: 22px !important;
  }
}
@media screen and (max-width: 1366px) {
  .fz_22_lg {
    font-size: 22px !important;
  }
}
@media screen and (max-width: 1024px) {
  .fz_22_mlg {
    font-size: 22px !important;
  }
}
@media screen and (max-width: 980px) {
  .fz_22_xlg {
    font-size: 22px !important;
  }
}
@media screen and (max-width: 834px) {
  .fz_22_md {
    font-size: 22px !important;
  }
}
@media screen and (max-width: 667px) {
  .fz_22_6ls {
    font-size: 22px !important;
  }
}
@media screen and (max-width: 568px) {
  .fz_22_5ls {
    font-sizxxe: 22px !important;
  }
}
@media screen and (max-width: 428px) {
  .fz_22_sm {
    font-size: 22px !important;
  }
}
@media screen and (max-width: 375px) {
  .fz_22_msm {
    font-size: 22px !important;
  }
}
@media screen and (max-width: 320px) {
  .fz_22_xs {
    font-size: 22px !important;
  }
}
.fz_23 {
  font-size: 23px !important;
}

@media screen and (max-width: 1980px) {
  .fz_23_xlg {
    font-size: 23px !important;
  }
}
@media screen and (max-width: 1366px) {
  .fz_23_lg {
    font-size: 23px !important;
  }
}
@media screen and (max-width: 1024px) {
  .fz_23_mlg {
    font-size: 23px !important;
  }
}
@media screen and (max-width: 980px) {
  .fz_23_xlg {
    font-size: 23px !important;
  }
}
@media screen and (max-width: 834px) {
  .fz_23_md {
    font-size: 23px !important;
  }
}
@media screen and (max-width: 667px) {
  .fz_23_6ls {
    font-size: 23px !important;
  }
}
@media screen and (max-width: 568px) {
  .fz_23_5ls {
    font-sizxxe: 23px !important;
  }
}
@media screen and (max-width: 428px) {
  .fz_23_sm {
    font-size: 23px !important;
  }
}
@media screen and (max-width: 375px) {
  .fz_23_msm {
    font-size: 23px !important;
  }
}
@media screen and (max-width: 320px) {
  .fz_23_xs {
    font-size: 23px !important;
  }
}
.fz_24 {
  font-size: 24px !important;
}

@media screen and (max-width: 1980px) {
  .fz_24_xlg {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 1366px) {
  .fz_24_lg {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 1024px) {
  .fz_24_mlg {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 980px) {
  .fz_24_xlg {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 834px) {
  .fz_24_md {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 667px) {
  .fz_24_6ls {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 568px) {
  .fz_24_5ls {
    font-sizxxe: 24px !important;
  }
}
@media screen and (max-width: 428px) {
  .fz_24_sm {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 375px) {
  .fz_24_msm {
    font-size: 24px !important;
  }
}
@media screen and (max-width: 320px) {
  .fz_24_xs {
    font-size: 24px !important;
  }
}
.fz_25 {
  font-size: 25px !important;
}

@media screen and (max-width: 1980px) {
  .fz_25_xlg {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 1366px) {
  .fz_25_lg {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 1024px) {
  .fz_25_mlg {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 980px) {
  .fz_25_xlg {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 834px) {
  .fz_25_md {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 667px) {
  .fz_25_6ls {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 568px) {
  .fz_25_5ls {
    font-sizxxe: 25px !important;
  }
}
@media screen and (max-width: 428px) {
  .fz_25_sm {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 375px) {
  .fz_25_msm {
    font-size: 25px !important;
  }
}
@media screen and (max-width: 320px) {
  .fz_25_xs {
    font-size: 25px !important;
  }
}
.fz_26 {
  font-size: 26px !important;
}

@media screen and (max-width: 1980px) {
  .fz_26_xlg {
    font-size: 26px !important;
  }
}
@media screen and (max-width: 1366px) {
  .fz_26_lg {
    font-size: 26px !important;
  }
}
@media screen and (max-width: 1024px) {
  .fz_26_mlg {
    font-size: 26px !important;
  }
}
@media screen and (max-width: 980px) {
  .fz_26_xlg {
    font-size: 26px !important;
  }
}
@media screen and (max-width: 834px) {
  .fz_26_md {
    font-size: 26px !important;
  }
}
@media screen and (max-width: 667px) {
  .fz_26_6ls {
    font-size: 26px !important;
  }
}
@media screen and (max-width: 568px) {
  .fz_26_5ls {
    font-sizxxe: 26px !important;
  }
}
@media screen and (max-width: 428px) {
  .fz_26_sm {
    font-size: 26px !important;
  }
}
@media screen and (max-width: 375px) {
  .fz_26_msm {
    font-size: 26px !important;
  }
}
@media screen and (max-width: 320px) {
  .fz_26_xs {
    font-size: 26px !important;
  }
}
.fz_27 {
  font-size: 27px !important;
}

@media screen and (max-width: 1980px) {
  .fz_27_xlg {
    font-size: 27px !important;
  }
}
@media screen and (max-width: 1366px) {
  .fz_27_lg {
    font-size: 27px !important;
  }
}
@media screen and (max-width: 1024px) {
  .fz_27_mlg {
    font-size: 27px !important;
  }
}
@media screen and (max-width: 980px) {
  .fz_27_xlg {
    font-size: 27px !important;
  }
}
@media screen and (max-width: 834px) {
  .fz_27_md {
    font-size: 27px !important;
  }
}
@media screen and (max-width: 667px) {
  .fz_27_6ls {
    font-size: 27px !important;
  }
}
@media screen and (max-width: 568px) {
  .fz_27_5ls {
    font-sizxxe: 27px !important;
  }
}
@media screen and (max-width: 428px) {
  .fz_27_sm {
    font-size: 27px !important;
  }
}
@media screen and (max-width: 375px) {
  .fz_27_msm {
    font-size: 27px !important;
  }
}
@media screen and (max-width: 320px) {
  .fz_27_xs {
    font-size: 27px !important;
  }
}
.fz_28 {
  font-size: 28px !important;
}

@media screen and (max-width: 1980px) {
  .fz_28_xlg {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 1366px) {
  .fz_28_lg {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 1024px) {
  .fz_28_mlg {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 980px) {
  .fz_28_xlg {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 834px) {
  .fz_28_md {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 667px) {
  .fz_28_6ls {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 568px) {
  .fz_28_5ls {
    font-sizxxe: 28px !important;
  }
}
@media screen and (max-width: 428px) {
  .fz_28_sm {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 375px) {
  .fz_28_msm {
    font-size: 28px !important;
  }
}
@media screen and (max-width: 320px) {
  .fz_28_xs {
    font-size: 28px !important;
  }
}
.fz_29 {
  font-size: 29px !important;
}

@media screen and (max-width: 1980px) {
  .fz_29_xlg {
    font-size: 29px !important;
  }
}
@media screen and (max-width: 1366px) {
  .fz_29_lg {
    font-size: 29px !important;
  }
}
@media screen and (max-width: 1024px) {
  .fz_29_mlg {
    font-size: 29px !important;
  }
}
@media screen and (max-width: 980px) {
  .fz_29_xlg {
    font-size: 29px !important;
  }
}
@media screen and (max-width: 834px) {
  .fz_29_md {
    font-size: 29px !important;
  }
}
@media screen and (max-width: 667px) {
  .fz_29_6ls {
    font-size: 29px !important;
  }
}
@media screen and (max-width: 568px) {
  .fz_29_5ls {
    font-sizxxe: 29px !important;
  }
}
@media screen and (max-width: 428px) {
  .fz_29_sm {
    font-size: 29px !important;
  }
}
@media screen and (max-width: 375px) {
  .fz_29_msm {
    font-size: 29px !important;
  }
}
@media screen and (max-width: 320px) {
  .fz_29_xs {
    font-size: 29px !important;
  }
}
.fz_30 {
  font-size: 30px !important;
}

@media screen and (max-width: 1980px) {
  .fz_30_xlg {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 1366px) {
  .fz_30_lg {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 1024px) {
  .fz_30_mlg {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 980px) {
  .fz_30_xlg {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 834px) {
  .fz_30_md {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 667px) {
  .fz_30_6ls {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 568px) {
  .fz_30_5ls {
    font-sizxxe: 30px !important;
  }
}
@media screen and (max-width: 428px) {
  .fz_30_sm {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 375px) {
  .fz_30_msm {
    font-size: 30px !important;
  }
}
@media screen and (max-width: 320px) {
  .fz_30_xs {
    font-size: 30px !important;
  }
}
.mb_0 {
  margin-bottom: 0px !important;
}

@media screen and (max-width: 1980px) {
  .mb_0_xlg {
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_0_lg {
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_0_mlg {
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_0_slg {
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_0_md {
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_0_6ls {
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_0_5ls {
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_0_sm {
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_0_msm {
    margin-bottom: 0px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_0_xs {
    margin-bottom: 0px !important;
  }
}
.mb_1 {
  margin-bottom: 1px !important;
}

@media screen and (max-width: 1980px) {
  .mb_1_xlg {
    margin-bottom: 1px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_1_lg {
    margin-bottom: 1px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_1_mlg {
    margin-bottom: 1px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_1_slg {
    margin-bottom: 1px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_1_md {
    margin-bottom: 1px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_1_6ls {
    margin-bottom: 1px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_1_5ls {
    margin-bottom: 1px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_1_sm {
    margin-bottom: 1px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_1_msm {
    margin-bottom: 1px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_1_xs {
    margin-bottom: 1px !important;
  }
}
.mb_2 {
  margin-bottom: 2px !important;
}

@media screen and (max-width: 1980px) {
  .mb_2_xlg {
    margin-bottom: 2px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_2_lg {
    margin-bottom: 2px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_2_mlg {
    margin-bottom: 2px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_2_slg {
    margin-bottom: 2px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_2_md {
    margin-bottom: 2px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_2_6ls {
    margin-bottom: 2px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_2_5ls {
    margin-bottom: 2px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_2_sm {
    margin-bottom: 2px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_2_msm {
    margin-bottom: 2px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_2_xs {
    margin-bottom: 2px !important;
  }
}
.mb_3 {
  margin-bottom: 3px !important;
}

@media screen and (max-width: 1980px) {
  .mb_3_xlg {
    margin-bottom: 3px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_3_lg {
    margin-bottom: 3px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_3_mlg {
    margin-bottom: 3px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_3_slg {
    margin-bottom: 3px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_3_md {
    margin-bottom: 3px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_3_6ls {
    margin-bottom: 3px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_3_5ls {
    margin-bottom: 3px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_3_sm {
    margin-bottom: 3px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_3_msm {
    margin-bottom: 3px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_3_xs {
    margin-bottom: 3px !important;
  }
}
.mb_4 {
  margin-bottom: 4px !important;
}

@media screen and (max-width: 1980px) {
  .mb_4_xlg {
    margin-bottom: 4px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_4_lg {
    margin-bottom: 4px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_4_mlg {
    margin-bottom: 4px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_4_slg {
    margin-bottom: 4px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_4_md {
    margin-bottom: 4px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_4_6ls {
    margin-bottom: 4px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_4_5ls {
    margin-bottom: 4px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_4_sm {
    margin-bottom: 4px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_4_msm {
    margin-bottom: 4px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_4_xs {
    margin-bottom: 4px !important;
  }
}
.mb_5 {
  margin-bottom: 5px !important;
}

@media screen and (max-width: 1980px) {
  .mb_5_xlg {
    margin-bottom: 5px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_5_lg {
    margin-bottom: 5px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_5_mlg {
    margin-bottom: 5px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_5_slg {
    margin-bottom: 5px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_5_md {
    margin-bottom: 5px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_5_6ls {
    margin-bottom: 5px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_5_5ls {
    margin-bottom: 5px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_5_sm {
    margin-bottom: 5px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_5_msm {
    margin-bottom: 5px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_5_xs {
    margin-bottom: 5px !important;
  }
}
.mb_6 {
  margin-bottom: 6px !important;
}

@media screen and (max-width: 1980px) {
  .mb_6_xlg {
    margin-bottom: 6px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_6_lg {
    margin-bottom: 6px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_6_mlg {
    margin-bottom: 6px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_6_slg {
    margin-bottom: 6px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_6_md {
    margin-bottom: 6px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_6_6ls {
    margin-bottom: 6px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_6_5ls {
    margin-bottom: 6px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_6_sm {
    margin-bottom: 6px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_6_msm {
    margin-bottom: 6px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_6_xs {
    margin-bottom: 6px !important;
  }
}
.mb_7 {
  margin-bottom: 7px !important;
}

@media screen and (max-width: 1980px) {
  .mb_7_xlg {
    margin-bottom: 7px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_7_lg {
    margin-bottom: 7px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_7_mlg {
    margin-bottom: 7px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_7_slg {
    margin-bottom: 7px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_7_md {
    margin-bottom: 7px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_7_6ls {
    margin-bottom: 7px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_7_5ls {
    margin-bottom: 7px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_7_sm {
    margin-bottom: 7px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_7_msm {
    margin-bottom: 7px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_7_xs {
    margin-bottom: 7px !important;
  }
}
.mb_8 {
  margin-bottom: 8px !important;
}

@media screen and (max-width: 1980px) {
  .mb_8_xlg {
    margin-bottom: 8px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_8_lg {
    margin-bottom: 8px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_8_mlg {
    margin-bottom: 8px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_8_slg {
    margin-bottom: 8px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_8_md {
    margin-bottom: 8px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_8_6ls {
    margin-bottom: 8px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_8_5ls {
    margin-bottom: 8px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_8_sm {
    margin-bottom: 8px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_8_msm {
    margin-bottom: 8px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_8_xs {
    margin-bottom: 8px !important;
  }
}
.mb_9 {
  margin-bottom: 9px !important;
}

@media screen and (max-width: 1980px) {
  .mb_9_xlg {
    margin-bottom: 9px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_9_lg {
    margin-bottom: 9px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_9_mlg {
    margin-bottom: 9px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_9_slg {
    margin-bottom: 9px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_9_md {
    margin-bottom: 9px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_9_6ls {
    margin-bottom: 9px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_9_5ls {
    margin-bottom: 9px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_9_sm {
    margin-bottom: 9px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_9_msm {
    margin-bottom: 9px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_9_xs {
    margin-bottom: 9px !important;
  }
}
.mb_10 {
  margin-bottom: 10px !important;
}

@media screen and (max-width: 1980px) {
  .mb_10_xlg {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_10_lg {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_10_mlg {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_10_slg {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_10_md {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_10_6ls {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_10_5ls {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_10_sm {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_10_msm {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_10_xs {
    margin-bottom: 10px !important;
  }
}
.mb_11 {
  margin-bottom: 11px !important;
}

@media screen and (max-width: 1980px) {
  .mb_11_xlg {
    margin-bottom: 11px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_11_lg {
    margin-bottom: 11px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_11_mlg {
    margin-bottom: 11px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_11_slg {
    margin-bottom: 11px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_11_md {
    margin-bottom: 11px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_11_6ls {
    margin-bottom: 11px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_11_5ls {
    margin-bottom: 11px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_11_sm {
    margin-bottom: 11px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_11_msm {
    margin-bottom: 11px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_11_xs {
    margin-bottom: 11px !important;
  }
}
.mb_12 {
  margin-bottom: 12px !important;
}

@media screen and (max-width: 1980px) {
  .mb_12_xlg {
    margin-bottom: 12px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_12_lg {
    margin-bottom: 12px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_12_mlg {
    margin-bottom: 12px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_12_slg {
    margin-bottom: 12px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_12_md {
    margin-bottom: 12px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_12_6ls {
    margin-bottom: 12px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_12_5ls {
    margin-bottom: 12px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_12_sm {
    margin-bottom: 12px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_12_msm {
    margin-bottom: 12px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_12_xs {
    margin-bottom: 12px !important;
  }
}
.mb_13 {
  margin-bottom: 13px !important;
}

@media screen and (max-width: 1980px) {
  .mb_13_xlg {
    margin-bottom: 13px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_13_lg {
    margin-bottom: 13px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_13_mlg {
    margin-bottom: 13px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_13_slg {
    margin-bottom: 13px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_13_md {
    margin-bottom: 13px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_13_6ls {
    margin-bottom: 13px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_13_5ls {
    margin-bottom: 13px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_13_sm {
    margin-bottom: 13px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_13_msm {
    margin-bottom: 13px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_13_xs {
    margin-bottom: 13px !important;
  }
}
.mb_14 {
  margin-bottom: 14px !important;
}

@media screen and (max-width: 1980px) {
  .mb_14_xlg {
    margin-bottom: 14px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_14_lg {
    margin-bottom: 14px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_14_mlg {
    margin-bottom: 14px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_14_slg {
    margin-bottom: 14px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_14_md {
    margin-bottom: 14px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_14_6ls {
    margin-bottom: 14px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_14_5ls {
    margin-bottom: 14px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_14_sm {
    margin-bottom: 14px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_14_msm {
    margin-bottom: 14px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_14_xs {
    margin-bottom: 14px !important;
  }
}
.mb_15 {
  margin-bottom: 15px !important;
}

@media screen and (max-width: 1980px) {
  .mb_15_xlg {
    margin-bottom: 15px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_15_lg {
    margin-bottom: 15px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_15_mlg {
    margin-bottom: 15px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_15_slg {
    margin-bottom: 15px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_15_md {
    margin-bottom: 15px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_15_6ls {
    margin-bottom: 15px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_15_5ls {
    margin-bottom: 15px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_15_sm {
    margin-bottom: 15px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_15_msm {
    margin-bottom: 15px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_15_xs {
    margin-bottom: 15px !important;
  }
}
.mb_16 {
  margin-bottom: 16px !important;
}

@media screen and (max-width: 1980px) {
  .mb_16_xlg {
    margin-bottom: 16px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_16_lg {
    margin-bottom: 16px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_16_mlg {
    margin-bottom: 16px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_16_slg {
    margin-bottom: 16px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_16_md {
    margin-bottom: 16px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_16_6ls {
    margin-bottom: 16px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_16_5ls {
    margin-bottom: 16px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_16_sm {
    margin-bottom: 16px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_16_msm {
    margin-bottom: 16px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_16_xs {
    margin-bottom: 16px !important;
  }
}
.mb_17 {
  margin-bottom: 17px !important;
}

@media screen and (max-width: 1980px) {
  .mb_17_xlg {
    margin-bottom: 17px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_17_lg {
    margin-bottom: 17px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_17_mlg {
    margin-bottom: 17px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_17_slg {
    margin-bottom: 17px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_17_md {
    margin-bottom: 17px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_17_6ls {
    margin-bottom: 17px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_17_5ls {
    margin-bottom: 17px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_17_sm {
    margin-bottom: 17px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_17_msm {
    margin-bottom: 17px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_17_xs {
    margin-bottom: 17px !important;
  }
}
.mb_18 {
  margin-bottom: 18px !important;
}

@media screen and (max-width: 1980px) {
  .mb_18_xlg {
    margin-bottom: 18px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_18_lg {
    margin-bottom: 18px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_18_mlg {
    margin-bottom: 18px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_18_slg {
    margin-bottom: 18px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_18_md {
    margin-bottom: 18px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_18_6ls {
    margin-bottom: 18px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_18_5ls {
    margin-bottom: 18px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_18_sm {
    margin-bottom: 18px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_18_msm {
    margin-bottom: 18px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_18_xs {
    margin-bottom: 18px !important;
  }
}
.mb_19 {
  margin-bottom: 19px !important;
}

@media screen and (max-width: 1980px) {
  .mb_19_xlg {
    margin-bottom: 19px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_19_lg {
    margin-bottom: 19px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_19_mlg {
    margin-bottom: 19px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_19_slg {
    margin-bottom: 19px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_19_md {
    margin-bottom: 19px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_19_6ls {
    margin-bottom: 19px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_19_5ls {
    margin-bottom: 19px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_19_sm {
    margin-bottom: 19px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_19_msm {
    margin-bottom: 19px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_19_xs {
    margin-bottom: 19px !important;
  }
}
.mb_20 {
  margin-bottom: 20px !important;
}

@media screen and (max-width: 1980px) {
  .mb_20_xlg {
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_20_lg {
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_20_mlg {
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_20_slg {
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_20_md {
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_20_6ls {
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_20_5ls {
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_20_sm {
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_20_msm {
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_20_xs {
    margin-bottom: 20px !important;
  }
}
.mb_21 {
  margin-bottom: 21px !important;
}

@media screen and (max-width: 1980px) {
  .mb_21_xlg {
    margin-bottom: 21px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_21_lg {
    margin-bottom: 21px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_21_mlg {
    margin-bottom: 21px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_21_slg {
    margin-bottom: 21px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_21_md {
    margin-bottom: 21px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_21_6ls {
    margin-bottom: 21px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_21_5ls {
    margin-bottom: 21px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_21_sm {
    margin-bottom: 21px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_21_msm {
    margin-bottom: 21px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_21_xs {
    margin-bottom: 21px !important;
  }
}
.mb_22 {
  margin-bottom: 22px !important;
}

@media screen and (max-width: 1980px) {
  .mb_22_xlg {
    margin-bottom: 22px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_22_lg {
    margin-bottom: 22px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_22_mlg {
    margin-bottom: 22px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_22_slg {
    margin-bottom: 22px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_22_md {
    margin-bottom: 22px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_22_6ls {
    margin-bottom: 22px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_22_5ls {
    margin-bottom: 22px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_22_sm {
    margin-bottom: 22px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_22_msm {
    margin-bottom: 22px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_22_xs {
    margin-bottom: 22px !important;
  }
}
.mb_23 {
  margin-bottom: 23px !important;
}

@media screen and (max-width: 1980px) {
  .mb_23_xlg {
    margin-bottom: 23px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_23_lg {
    margin-bottom: 23px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_23_mlg {
    margin-bottom: 23px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_23_slg {
    margin-bottom: 23px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_23_md {
    margin-bottom: 23px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_23_6ls {
    margin-bottom: 23px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_23_5ls {
    margin-bottom: 23px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_23_sm {
    margin-bottom: 23px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_23_msm {
    margin-bottom: 23px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_23_xs {
    margin-bottom: 23px !important;
  }
}
.mb_24 {
  margin-bottom: 24px !important;
}

@media screen and (max-width: 1980px) {
  .mb_24_xlg {
    margin-bottom: 24px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_24_lg {
    margin-bottom: 24px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_24_mlg {
    margin-bottom: 24px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_24_slg {
    margin-bottom: 24px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_24_md {
    margin-bottom: 24px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_24_6ls {
    margin-bottom: 24px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_24_5ls {
    margin-bottom: 24px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_24_sm {
    margin-bottom: 24px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_24_msm {
    margin-bottom: 24px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_24_xs {
    margin-bottom: 24px !important;
  }
}
.mb_25 {
  margin-bottom: 25px !important;
}

@media screen and (max-width: 1980px) {
  .mb_25_xlg {
    margin-bottom: 25px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_25_lg {
    margin-bottom: 25px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_25_mlg {
    margin-bottom: 25px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_25_slg {
    margin-bottom: 25px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_25_md {
    margin-bottom: 25px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_25_6ls {
    margin-bottom: 25px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_25_5ls {
    margin-bottom: 25px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_25_sm {
    margin-bottom: 25px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_25_msm {
    margin-bottom: 25px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_25_xs {
    margin-bottom: 25px !important;
  }
}
.mb_26 {
  margin-bottom: 26px !important;
}

@media screen and (max-width: 1980px) {
  .mb_26_xlg {
    margin-bottom: 26px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_26_lg {
    margin-bottom: 26px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_26_mlg {
    margin-bottom: 26px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_26_slg {
    margin-bottom: 26px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_26_md {
    margin-bottom: 26px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_26_6ls {
    margin-bottom: 26px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_26_5ls {
    margin-bottom: 26px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_26_sm {
    margin-bottom: 26px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_26_msm {
    margin-bottom: 26px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_26_xs {
    margin-bottom: 26px !important;
  }
}
.mb_27 {
  margin-bottom: 27px !important;
}

@media screen and (max-width: 1980px) {
  .mb_27_xlg {
    margin-bottom: 27px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_27_lg {
    margin-bottom: 27px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_27_mlg {
    margin-bottom: 27px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_27_slg {
    margin-bottom: 27px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_27_md {
    margin-bottom: 27px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_27_6ls {
    margin-bottom: 27px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_27_5ls {
    margin-bottom: 27px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_27_sm {
    margin-bottom: 27px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_27_msm {
    margin-bottom: 27px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_27_xs {
    margin-bottom: 27px !important;
  }
}
.mb_28 {
  margin-bottom: 28px !important;
}

@media screen and (max-width: 1980px) {
  .mb_28_xlg {
    margin-bottom: 28px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_28_lg {
    margin-bottom: 28px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_28_mlg {
    margin-bottom: 28px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_28_slg {
    margin-bottom: 28px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_28_md {
    margin-bottom: 28px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_28_6ls {
    margin-bottom: 28px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_28_5ls {
    margin-bottom: 28px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_28_sm {
    margin-bottom: 28px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_28_msm {
    margin-bottom: 28px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_28_xs {
    margin-bottom: 28px !important;
  }
}
.mb_29 {
  margin-bottom: 29px !important;
}

@media screen and (max-width: 1980px) {
  .mb_29_xlg {
    margin-bottom: 29px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_29_lg {
    margin-bottom: 29px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_29_mlg {
    margin-bottom: 29px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_29_slg {
    margin-bottom: 29px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_29_md {
    margin-bottom: 29px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_29_6ls {
    margin-bottom: 29px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_29_5ls {
    margin-bottom: 29px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_29_sm {
    margin-bottom: 29px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_29_msm {
    margin-bottom: 29px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_29_xs {
    margin-bottom: 29px !important;
  }
}
.mb_30 {
  margin-bottom: 30px !important;
}

@media screen and (max-width: 1980px) {
  .mb_30_xlg {
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_30_lg {
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_30_mlg {
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_30_slg {
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_30_md {
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_30_6ls {
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_30_5ls {
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_30_sm {
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_30_msm {
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_30_xs {
    margin-bottom: 30px !important;
  }
}
.mb_31 {
  margin-bottom: 31px !important;
}

@media screen and (max-width: 1980px) {
  .mb_31_xlg {
    margin-bottom: 31px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_31_lg {
    margin-bottom: 31px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_31_mlg {
    margin-bottom: 31px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_31_slg {
    margin-bottom: 31px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_31_md {
    margin-bottom: 31px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_31_6ls {
    margin-bottom: 31px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_31_5ls {
    margin-bottom: 31px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_31_sm {
    margin-bottom: 31px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_31_msm {
    margin-bottom: 31px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_31_xs {
    margin-bottom: 31px !important;
  }
}
.mb_32 {
  margin-bottom: 32px !important;
}

@media screen and (max-width: 1980px) {
  .mb_32_xlg {
    margin-bottom: 32px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_32_lg {
    margin-bottom: 32px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_32_mlg {
    margin-bottom: 32px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_32_slg {
    margin-bottom: 32px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_32_md {
    margin-bottom: 32px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_32_6ls {
    margin-bottom: 32px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_32_5ls {
    margin-bottom: 32px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_32_sm {
    margin-bottom: 32px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_32_msm {
    margin-bottom: 32px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_32_xs {
    margin-bottom: 32px !important;
  }
}
.mb_33 {
  margin-bottom: 33px !important;
}

@media screen and (max-width: 1980px) {
  .mb_33_xlg {
    margin-bottom: 33px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_33_lg {
    margin-bottom: 33px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_33_mlg {
    margin-bottom: 33px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_33_slg {
    margin-bottom: 33px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_33_md {
    margin-bottom: 33px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_33_6ls {
    margin-bottom: 33px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_33_5ls {
    margin-bottom: 33px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_33_sm {
    margin-bottom: 33px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_33_msm {
    margin-bottom: 33px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_33_xs {
    margin-bottom: 33px !important;
  }
}
.mb_34 {
  margin-bottom: 34px !important;
}

@media screen and (max-width: 1980px) {
  .mb_34_xlg {
    margin-bottom: 34px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_34_lg {
    margin-bottom: 34px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_34_mlg {
    margin-bottom: 34px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_34_slg {
    margin-bottom: 34px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_34_md {
    margin-bottom: 34px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_34_6ls {
    margin-bottom: 34px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_34_5ls {
    margin-bottom: 34px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_34_sm {
    margin-bottom: 34px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_34_msm {
    margin-bottom: 34px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_34_xs {
    margin-bottom: 34px !important;
  }
}
.mb_35 {
  margin-bottom: 35px !important;
}

@media screen and (max-width: 1980px) {
  .mb_35_xlg {
    margin-bottom: 35px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_35_lg {
    margin-bottom: 35px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_35_mlg {
    margin-bottom: 35px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_35_slg {
    margin-bottom: 35px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_35_md {
    margin-bottom: 35px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_35_6ls {
    margin-bottom: 35px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_35_5ls {
    margin-bottom: 35px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_35_sm {
    margin-bottom: 35px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_35_msm {
    margin-bottom: 35px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_35_xs {
    margin-bottom: 35px !important;
  }
}
.mb_36 {
  margin-bottom: 36px !important;
}

@media screen and (max-width: 1980px) {
  .mb_36_xlg {
    margin-bottom: 36px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_36_lg {
    margin-bottom: 36px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_36_mlg {
    margin-bottom: 36px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_36_slg {
    margin-bottom: 36px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_36_md {
    margin-bottom: 36px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_36_6ls {
    margin-bottom: 36px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_36_5ls {
    margin-bottom: 36px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_36_sm {
    margin-bottom: 36px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_36_msm {
    margin-bottom: 36px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_36_xs {
    margin-bottom: 36px !important;
  }
}
.mb_37 {
  margin-bottom: 37px !important;
}

@media screen and (max-width: 1980px) {
  .mb_37_xlg {
    margin-bottom: 37px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_37_lg {
    margin-bottom: 37px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_37_mlg {
    margin-bottom: 37px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_37_slg {
    margin-bottom: 37px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_37_md {
    margin-bottom: 37px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_37_6ls {
    margin-bottom: 37px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_37_5ls {
    margin-bottom: 37px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_37_sm {
    margin-bottom: 37px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_37_msm {
    margin-bottom: 37px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_37_xs {
    margin-bottom: 37px !important;
  }
}
.mb_38 {
  margin-bottom: 38px !important;
}

@media screen and (max-width: 1980px) {
  .mb_38_xlg {
    margin-bottom: 38px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_38_lg {
    margin-bottom: 38px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_38_mlg {
    margin-bottom: 38px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_38_slg {
    margin-bottom: 38px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_38_md {
    margin-bottom: 38px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_38_6ls {
    margin-bottom: 38px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_38_5ls {
    margin-bottom: 38px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_38_sm {
    margin-bottom: 38px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_38_msm {
    margin-bottom: 38px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_38_xs {
    margin-bottom: 38px !important;
  }
}
.mb_39 {
  margin-bottom: 39px !important;
}

@media screen and (max-width: 1980px) {
  .mb_39_xlg {
    margin-bottom: 39px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_39_lg {
    margin-bottom: 39px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_39_mlg {
    margin-bottom: 39px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_39_slg {
    margin-bottom: 39px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_39_md {
    margin-bottom: 39px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_39_6ls {
    margin-bottom: 39px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_39_5ls {
    margin-bottom: 39px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_39_sm {
    margin-bottom: 39px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_39_msm {
    margin-bottom: 39px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_39_xs {
    margin-bottom: 39px !important;
  }
}
.mb_40 {
  margin-bottom: 40px !important;
}

@media screen and (max-width: 1980px) {
  .mb_40_xlg {
    margin-bottom: 40px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_40_lg {
    margin-bottom: 40px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_40_mlg {
    margin-bottom: 40px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_40_slg {
    margin-bottom: 40px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_40_md {
    margin-bottom: 40px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_40_6ls {
    margin-bottom: 40px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_40_5ls {
    margin-bottom: 40px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_40_sm {
    margin-bottom: 40px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_40_msm {
    margin-bottom: 40px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_40_xs {
    margin-bottom: 40px !important;
  }
}
.mb_41 {
  margin-bottom: 41px !important;
}

@media screen and (max-width: 1980px) {
  .mb_41_xlg {
    margin-bottom: 41px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_41_lg {
    margin-bottom: 41px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_41_mlg {
    margin-bottom: 41px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_41_slg {
    margin-bottom: 41px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_41_md {
    margin-bottom: 41px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_41_6ls {
    margin-bottom: 41px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_41_5ls {
    margin-bottom: 41px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_41_sm {
    margin-bottom: 41px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_41_msm {
    margin-bottom: 41px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_41_xs {
    margin-bottom: 41px !important;
  }
}
.mb_42 {
  margin-bottom: 42px !important;
}

@media screen and (max-width: 1980px) {
  .mb_42_xlg {
    margin-bottom: 42px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_42_lg {
    margin-bottom: 42px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_42_mlg {
    margin-bottom: 42px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_42_slg {
    margin-bottom: 42px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_42_md {
    margin-bottom: 42px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_42_6ls {
    margin-bottom: 42px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_42_5ls {
    margin-bottom: 42px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_42_sm {
    margin-bottom: 42px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_42_msm {
    margin-bottom: 42px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_42_xs {
    margin-bottom: 42px !important;
  }
}
.mb_43 {
  margin-bottom: 43px !important;
}

@media screen and (max-width: 1980px) {
  .mb_43_xlg {
    margin-bottom: 43px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_43_lg {
    margin-bottom: 43px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_43_mlg {
    margin-bottom: 43px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_43_slg {
    margin-bottom: 43px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_43_md {
    margin-bottom: 43px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_43_6ls {
    margin-bottom: 43px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_43_5ls {
    margin-bottom: 43px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_43_sm {
    margin-bottom: 43px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_43_msm {
    margin-bottom: 43px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_43_xs {
    margin-bottom: 43px !important;
  }
}
.mb_44 {
  margin-bottom: 44px !important;
}

@media screen and (max-width: 1980px) {
  .mb_44_xlg {
    margin-bottom: 44px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_44_lg {
    margin-bottom: 44px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_44_mlg {
    margin-bottom: 44px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_44_slg {
    margin-bottom: 44px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_44_md {
    margin-bottom: 44px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_44_6ls {
    margin-bottom: 44px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_44_5ls {
    margin-bottom: 44px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_44_sm {
    margin-bottom: 44px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_44_msm {
    margin-bottom: 44px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_44_xs {
    margin-bottom: 44px !important;
  }
}
.mb_45 {
  margin-bottom: 45px !important;
}

@media screen and (max-width: 1980px) {
  .mb_45_xlg {
    margin-bottom: 45px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_45_lg {
    margin-bottom: 45px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_45_mlg {
    margin-bottom: 45px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_45_slg {
    margin-bottom: 45px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_45_md {
    margin-bottom: 45px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_45_6ls {
    margin-bottom: 45px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_45_5ls {
    margin-bottom: 45px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_45_sm {
    margin-bottom: 45px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_45_msm {
    margin-bottom: 45px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_45_xs {
    margin-bottom: 45px !important;
  }
}
.mb_46 {
  margin-bottom: 46px !important;
}

@media screen and (max-width: 1980px) {
  .mb_46_xlg {
    margin-bottom: 46px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_46_lg {
    margin-bottom: 46px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_46_mlg {
    margin-bottom: 46px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_46_slg {
    margin-bottom: 46px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_46_md {
    margin-bottom: 46px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_46_6ls {
    margin-bottom: 46px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_46_5ls {
    margin-bottom: 46px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_46_sm {
    margin-bottom: 46px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_46_msm {
    margin-bottom: 46px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_46_xs {
    margin-bottom: 46px !important;
  }
}
.mb_47 {
  margin-bottom: 47px !important;
}

@media screen and (max-width: 1980px) {
  .mb_47_xlg {
    margin-bottom: 47px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_47_lg {
    margin-bottom: 47px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_47_mlg {
    margin-bottom: 47px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_47_slg {
    margin-bottom: 47px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_47_md {
    margin-bottom: 47px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_47_6ls {
    margin-bottom: 47px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_47_5ls {
    margin-bottom: 47px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_47_sm {
    margin-bottom: 47px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_47_msm {
    margin-bottom: 47px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_47_xs {
    margin-bottom: 47px !important;
  }
}
.mb_48 {
  margin-bottom: 48px !important;
}

@media screen and (max-width: 1980px) {
  .mb_48_xlg {
    margin-bottom: 48px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_48_lg {
    margin-bottom: 48px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_48_mlg {
    margin-bottom: 48px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_48_slg {
    margin-bottom: 48px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_48_md {
    margin-bottom: 48px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_48_6ls {
    margin-bottom: 48px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_48_5ls {
    margin-bottom: 48px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_48_sm {
    margin-bottom: 48px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_48_msm {
    margin-bottom: 48px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_48_xs {
    margin-bottom: 48px !important;
  }
}
.mb_49 {
  margin-bottom: 49px !important;
}

@media screen and (max-width: 1980px) {
  .mb_49_xlg {
    margin-bottom: 49px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_49_lg {
    margin-bottom: 49px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_49_mlg {
    margin-bottom: 49px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_49_slg {
    margin-bottom: 49px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_49_md {
    margin-bottom: 49px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_49_6ls {
    margin-bottom: 49px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_49_5ls {
    margin-bottom: 49px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_49_sm {
    margin-bottom: 49px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_49_msm {
    margin-bottom: 49px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_49_xs {
    margin-bottom: 49px !important;
  }
}
.mb_50 {
  margin-bottom: 50px !important;
}

@media screen and (max-width: 1980px) {
  .mb_50_xlg {
    margin-bottom: 50px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_50_lg {
    margin-bottom: 50px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_50_mlg {
    margin-bottom: 50px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_50_slg {
    margin-bottom: 50px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_50_md {
    margin-bottom: 50px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_50_6ls {
    margin-bottom: 50px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_50_5ls {
    margin-bottom: 50px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_50_sm {
    margin-bottom: 50px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_50_msm {
    margin-bottom: 50px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_50_xs {
    margin-bottom: 50px !important;
  }
}
.mb_51 {
  margin-bottom: 51px !important;
}

@media screen and (max-width: 1980px) {
  .mb_51_xlg {
    margin-bottom: 51px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_51_lg {
    margin-bottom: 51px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_51_mlg {
    margin-bottom: 51px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_51_slg {
    margin-bottom: 51px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_51_md {
    margin-bottom: 51px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_51_6ls {
    margin-bottom: 51px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_51_5ls {
    margin-bottom: 51px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_51_sm {
    margin-bottom: 51px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_51_msm {
    margin-bottom: 51px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_51_xs {
    margin-bottom: 51px !important;
  }
}
.mb_52 {
  margin-bottom: 52px !important;
}

@media screen and (max-width: 1980px) {
  .mb_52_xlg {
    margin-bottom: 52px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_52_lg {
    margin-bottom: 52px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_52_mlg {
    margin-bottom: 52px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_52_slg {
    margin-bottom: 52px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_52_md {
    margin-bottom: 52px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_52_6ls {
    margin-bottom: 52px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_52_5ls {
    margin-bottom: 52px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_52_sm {
    margin-bottom: 52px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_52_msm {
    margin-bottom: 52px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_52_xs {
    margin-bottom: 52px !important;
  }
}
.mb_53 {
  margin-bottom: 53px !important;
}

@media screen and (max-width: 1980px) {
  .mb_53_xlg {
    margin-bottom: 53px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_53_lg {
    margin-bottom: 53px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_53_mlg {
    margin-bottom: 53px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_53_slg {
    margin-bottom: 53px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_53_md {
    margin-bottom: 53px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_53_6ls {
    margin-bottom: 53px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_53_5ls {
    margin-bottom: 53px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_53_sm {
    margin-bottom: 53px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_53_msm {
    margin-bottom: 53px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_53_xs {
    margin-bottom: 53px !important;
  }
}
.mb_54 {
  margin-bottom: 54px !important;
}

@media screen and (max-width: 1980px) {
  .mb_54_xlg {
    margin-bottom: 54px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_54_lg {
    margin-bottom: 54px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_54_mlg {
    margin-bottom: 54px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_54_slg {
    margin-bottom: 54px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_54_md {
    margin-bottom: 54px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_54_6ls {
    margin-bottom: 54px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_54_5ls {
    margin-bottom: 54px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_54_sm {
    margin-bottom: 54px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_54_msm {
    margin-bottom: 54px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_54_xs {
    margin-bottom: 54px !important;
  }
}
.mb_55 {
  margin-bottom: 55px !important;
}

@media screen and (max-width: 1980px) {
  .mb_55_xlg {
    margin-bottom: 55px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_55_lg {
    margin-bottom: 55px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_55_mlg {
    margin-bottom: 55px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_55_slg {
    margin-bottom: 55px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_55_md {
    margin-bottom: 55px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_55_6ls {
    margin-bottom: 55px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_55_5ls {
    margin-bottom: 55px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_55_sm {
    margin-bottom: 55px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_55_msm {
    margin-bottom: 55px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_55_xs {
    margin-bottom: 55px !important;
  }
}
.mb_56 {
  margin-bottom: 56px !important;
}

@media screen and (max-width: 1980px) {
  .mb_56_xlg {
    margin-bottom: 56px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_56_lg {
    margin-bottom: 56px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_56_mlg {
    margin-bottom: 56px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_56_slg {
    margin-bottom: 56px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_56_md {
    margin-bottom: 56px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_56_6ls {
    margin-bottom: 56px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_56_5ls {
    margin-bottom: 56px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_56_sm {
    margin-bottom: 56px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_56_msm {
    margin-bottom: 56px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_56_xs {
    margin-bottom: 56px !important;
  }
}
.mb_57 {
  margin-bottom: 57px !important;
}

@media screen and (max-width: 1980px) {
  .mb_57_xlg {
    margin-bottom: 57px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_57_lg {
    margin-bottom: 57px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_57_mlg {
    margin-bottom: 57px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_57_slg {
    margin-bottom: 57px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_57_md {
    margin-bottom: 57px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_57_6ls {
    margin-bottom: 57px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_57_5ls {
    margin-bottom: 57px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_57_sm {
    margin-bottom: 57px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_57_msm {
    margin-bottom: 57px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_57_xs {
    margin-bottom: 57px !important;
  }
}
.mb_58 {
  margin-bottom: 58px !important;
}

@media screen and (max-width: 1980px) {
  .mb_58_xlg {
    margin-bottom: 58px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_58_lg {
    margin-bottom: 58px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_58_mlg {
    margin-bottom: 58px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_58_slg {
    margin-bottom: 58px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_58_md {
    margin-bottom: 58px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_58_6ls {
    margin-bottom: 58px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_58_5ls {
    margin-bottom: 58px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_58_sm {
    margin-bottom: 58px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_58_msm {
    margin-bottom: 58px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_58_xs {
    margin-bottom: 58px !important;
  }
}
.mb_59 {
  margin-bottom: 59px !important;
}

@media screen and (max-width: 1980px) {
  .mb_59_xlg {
    margin-bottom: 59px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_59_lg {
    margin-bottom: 59px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_59_mlg {
    margin-bottom: 59px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_59_slg {
    margin-bottom: 59px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_59_md {
    margin-bottom: 59px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_59_6ls {
    margin-bottom: 59px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_59_5ls {
    margin-bottom: 59px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_59_sm {
    margin-bottom: 59px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_59_msm {
    margin-bottom: 59px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_59_xs {
    margin-bottom: 59px !important;
  }
}
.mb_60 {
  margin-bottom: 60px !important;
}

@media screen and (max-width: 1980px) {
  .mb_60_xlg {
    margin-bottom: 60px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_60_lg {
    margin-bottom: 60px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_60_mlg {
    margin-bottom: 60px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_60_slg {
    margin-bottom: 60px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_60_md {
    margin-bottom: 60px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_60_6ls {
    margin-bottom: 60px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_60_5ls {
    margin-bottom: 60px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_60_sm {
    margin-bottom: 60px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_60_msm {
    margin-bottom: 60px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_60_xs {
    margin-bottom: 60px !important;
  }
}
.mb_61 {
  margin-bottom: 61px !important;
}

@media screen and (max-width: 1980px) {
  .mb_61_xlg {
    margin-bottom: 61px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_61_lg {
    margin-bottom: 61px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_61_mlg {
    margin-bottom: 61px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_61_slg {
    margin-bottom: 61px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_61_md {
    margin-bottom: 61px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_61_6ls {
    margin-bottom: 61px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_61_5ls {
    margin-bottom: 61px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_61_sm {
    margin-bottom: 61px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_61_msm {
    margin-bottom: 61px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_61_xs {
    margin-bottom: 61px !important;
  }
}
.mb_62 {
  margin-bottom: 62px !important;
}

@media screen and (max-width: 1980px) {
  .mb_62_xlg {
    margin-bottom: 62px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_62_lg {
    margin-bottom: 62px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_62_mlg {
    margin-bottom: 62px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_62_slg {
    margin-bottom: 62px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_62_md {
    margin-bottom: 62px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_62_6ls {
    margin-bottom: 62px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_62_5ls {
    margin-bottom: 62px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_62_sm {
    margin-bottom: 62px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_62_msm {
    margin-bottom: 62px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_62_xs {
    margin-bottom: 62px !important;
  }
}
.mb_63 {
  margin-bottom: 63px !important;
}

@media screen and (max-width: 1980px) {
  .mb_63_xlg {
    margin-bottom: 63px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_63_lg {
    margin-bottom: 63px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_63_mlg {
    margin-bottom: 63px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_63_slg {
    margin-bottom: 63px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_63_md {
    margin-bottom: 63px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_63_6ls {
    margin-bottom: 63px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_63_5ls {
    margin-bottom: 63px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_63_sm {
    margin-bottom: 63px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_63_msm {
    margin-bottom: 63px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_63_xs {
    margin-bottom: 63px !important;
  }
}
.mb_64 {
  margin-bottom: 64px !important;
}

@media screen and (max-width: 1980px) {
  .mb_64_xlg {
    margin-bottom: 64px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_64_lg {
    margin-bottom: 64px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_64_mlg {
    margin-bottom: 64px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_64_slg {
    margin-bottom: 64px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_64_md {
    margin-bottom: 64px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_64_6ls {
    margin-bottom: 64px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_64_5ls {
    margin-bottom: 64px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_64_sm {
    margin-bottom: 64px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_64_msm {
    margin-bottom: 64px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_64_xs {
    margin-bottom: 64px !important;
  }
}
.mb_65 {
  margin-bottom: 65px !important;
}

@media screen and (max-width: 1980px) {
  .mb_65_xlg {
    margin-bottom: 65px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_65_lg {
    margin-bottom: 65px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_65_mlg {
    margin-bottom: 65px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_65_slg {
    margin-bottom: 65px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_65_md {
    margin-bottom: 65px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_65_6ls {
    margin-bottom: 65px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_65_5ls {
    margin-bottom: 65px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_65_sm {
    margin-bottom: 65px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_65_msm {
    margin-bottom: 65px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_65_xs {
    margin-bottom: 65px !important;
  }
}
.mb_66 {
  margin-bottom: 66px !important;
}

@media screen and (max-width: 1980px) {
  .mb_66_xlg {
    margin-bottom: 66px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_66_lg {
    margin-bottom: 66px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_66_mlg {
    margin-bottom: 66px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_66_slg {
    margin-bottom: 66px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_66_md {
    margin-bottom: 66px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_66_6ls {
    margin-bottom: 66px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_66_5ls {
    margin-bottom: 66px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_66_sm {
    margin-bottom: 66px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_66_msm {
    margin-bottom: 66px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_66_xs {
    margin-bottom: 66px !important;
  }
}
.mb_67 {
  margin-bottom: 67px !important;
}

@media screen and (max-width: 1980px) {
  .mb_67_xlg {
    margin-bottom: 67px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_67_lg {
    margin-bottom: 67px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_67_mlg {
    margin-bottom: 67px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_67_slg {
    margin-bottom: 67px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_67_md {
    margin-bottom: 67px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_67_6ls {
    margin-bottom: 67px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_67_5ls {
    margin-bottom: 67px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_67_sm {
    margin-bottom: 67px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_67_msm {
    margin-bottom: 67px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_67_xs {
    margin-bottom: 67px !important;
  }
}
.mb_68 {
  margin-bottom: 68px !important;
}

@media screen and (max-width: 1980px) {
  .mb_68_xlg {
    margin-bottom: 68px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_68_lg {
    margin-bottom: 68px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_68_mlg {
    margin-bottom: 68px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_68_slg {
    margin-bottom: 68px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_68_md {
    margin-bottom: 68px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_68_6ls {
    margin-bottom: 68px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_68_5ls {
    margin-bottom: 68px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_68_sm {
    margin-bottom: 68px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_68_msm {
    margin-bottom: 68px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_68_xs {
    margin-bottom: 68px !important;
  }
}
.mb_69 {
  margin-bottom: 69px !important;
}

@media screen and (max-width: 1980px) {
  .mb_69_xlg {
    margin-bottom: 69px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_69_lg {
    margin-bottom: 69px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_69_mlg {
    margin-bottom: 69px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_69_slg {
    margin-bottom: 69px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_69_md {
    margin-bottom: 69px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_69_6ls {
    margin-bottom: 69px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_69_5ls {
    margin-bottom: 69px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_69_sm {
    margin-bottom: 69px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_69_msm {
    margin-bottom: 69px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_69_xs {
    margin-bottom: 69px !important;
  }
}
.mb_70 {
  margin-bottom: 70px !important;
}

@media screen and (max-width: 1980px) {
  .mb_70_xlg {
    margin-bottom: 70px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_70_lg {
    margin-bottom: 70px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_70_mlg {
    margin-bottom: 70px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_70_slg {
    margin-bottom: 70px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_70_md {
    margin-bottom: 70px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_70_6ls {
    margin-bottom: 70px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_70_5ls {
    margin-bottom: 70px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_70_sm {
    margin-bottom: 70px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_70_msm {
    margin-bottom: 70px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_70_xs {
    margin-bottom: 70px !important;
  }
}
.mb_71 {
  margin-bottom: 71px !important;
}

@media screen and (max-width: 1980px) {
  .mb_71_xlg {
    margin-bottom: 71px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_71_lg {
    margin-bottom: 71px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_71_mlg {
    margin-bottom: 71px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_71_slg {
    margin-bottom: 71px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_71_md {
    margin-bottom: 71px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_71_6ls {
    margin-bottom: 71px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_71_5ls {
    margin-bottom: 71px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_71_sm {
    margin-bottom: 71px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_71_msm {
    margin-bottom: 71px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_71_xs {
    margin-bottom: 71px !important;
  }
}
.mb_72 {
  margin-bottom: 72px !important;
}

@media screen and (max-width: 1980px) {
  .mb_72_xlg {
    margin-bottom: 72px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_72_lg {
    margin-bottom: 72px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_72_mlg {
    margin-bottom: 72px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_72_slg {
    margin-bottom: 72px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_72_md {
    margin-bottom: 72px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_72_6ls {
    margin-bottom: 72px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_72_5ls {
    margin-bottom: 72px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_72_sm {
    margin-bottom: 72px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_72_msm {
    margin-bottom: 72px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_72_xs {
    margin-bottom: 72px !important;
  }
}
.mb_73 {
  margin-bottom: 73px !important;
}

@media screen and (max-width: 1980px) {
  .mb_73_xlg {
    margin-bottom: 73px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_73_lg {
    margin-bottom: 73px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_73_mlg {
    margin-bottom: 73px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_73_slg {
    margin-bottom: 73px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_73_md {
    margin-bottom: 73px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_73_6ls {
    margin-bottom: 73px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_73_5ls {
    margin-bottom: 73px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_73_sm {
    margin-bottom: 73px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_73_msm {
    margin-bottom: 73px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_73_xs {
    margin-bottom: 73px !important;
  }
}
.mb_74 {
  margin-bottom: 74px !important;
}

@media screen and (max-width: 1980px) {
  .mb_74_xlg {
    margin-bottom: 74px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_74_lg {
    margin-bottom: 74px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_74_mlg {
    margin-bottom: 74px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_74_slg {
    margin-bottom: 74px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_74_md {
    margin-bottom: 74px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_74_6ls {
    margin-bottom: 74px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_74_5ls {
    margin-bottom: 74px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_74_sm {
    margin-bottom: 74px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_74_msm {
    margin-bottom: 74px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_74_xs {
    margin-bottom: 74px !important;
  }
}
.mb_75 {
  margin-bottom: 75px !important;
}

@media screen and (max-width: 1980px) {
  .mb_75_xlg {
    margin-bottom: 75px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_75_lg {
    margin-bottom: 75px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_75_mlg {
    margin-bottom: 75px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_75_slg {
    margin-bottom: 75px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_75_md {
    margin-bottom: 75px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_75_6ls {
    margin-bottom: 75px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_75_5ls {
    margin-bottom: 75px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_75_sm {
    margin-bottom: 75px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_75_msm {
    margin-bottom: 75px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_75_xs {
    margin-bottom: 75px !important;
  }
}
.mb_76 {
  margin-bottom: 76px !important;
}

@media screen and (max-width: 1980px) {
  .mb_76_xlg {
    margin-bottom: 76px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_76_lg {
    margin-bottom: 76px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_76_mlg {
    margin-bottom: 76px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_76_slg {
    margin-bottom: 76px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_76_md {
    margin-bottom: 76px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_76_6ls {
    margin-bottom: 76px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_76_5ls {
    margin-bottom: 76px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_76_sm {
    margin-bottom: 76px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_76_msm {
    margin-bottom: 76px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_76_xs {
    margin-bottom: 76px !important;
  }
}
.mb_77 {
  margin-bottom: 77px !important;
}

@media screen and (max-width: 1980px) {
  .mb_77_xlg {
    margin-bottom: 77px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_77_lg {
    margin-bottom: 77px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_77_mlg {
    margin-bottom: 77px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_77_slg {
    margin-bottom: 77px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_77_md {
    margin-bottom: 77px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_77_6ls {
    margin-bottom: 77px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_77_5ls {
    margin-bottom: 77px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_77_sm {
    margin-bottom: 77px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_77_msm {
    margin-bottom: 77px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_77_xs {
    margin-bottom: 77px !important;
  }
}
.mb_78 {
  margin-bottom: 78px !important;
}

@media screen and (max-width: 1980px) {
  .mb_78_xlg {
    margin-bottom: 78px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_78_lg {
    margin-bottom: 78px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_78_mlg {
    margin-bottom: 78px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_78_slg {
    margin-bottom: 78px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_78_md {
    margin-bottom: 78px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_78_6ls {
    margin-bottom: 78px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_78_5ls {
    margin-bottom: 78px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_78_sm {
    margin-bottom: 78px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_78_msm {
    margin-bottom: 78px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_78_xs {
    margin-bottom: 78px !important;
  }
}
.mb_79 {
  margin-bottom: 79px !important;
}

@media screen and (max-width: 1980px) {
  .mb_79_xlg {
    margin-bottom: 79px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_79_lg {
    margin-bottom: 79px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_79_mlg {
    margin-bottom: 79px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_79_slg {
    margin-bottom: 79px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_79_md {
    margin-bottom: 79px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_79_6ls {
    margin-bottom: 79px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_79_5ls {
    margin-bottom: 79px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_79_sm {
    margin-bottom: 79px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_79_msm {
    margin-bottom: 79px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_79_xs {
    margin-bottom: 79px !important;
  }
}
.mb_80 {
  margin-bottom: 80px !important;
}

@media screen and (max-width: 1980px) {
  .mb_80_xlg {
    margin-bottom: 80px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_80_lg {
    margin-bottom: 80px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_80_mlg {
    margin-bottom: 80px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_80_slg {
    margin-bottom: 80px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_80_md {
    margin-bottom: 80px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_80_6ls {
    margin-bottom: 80px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_80_5ls {
    margin-bottom: 80px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_80_sm {
    margin-bottom: 80px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_80_msm {
    margin-bottom: 80px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_80_xs {
    margin-bottom: 80px !important;
  }
}
.mb_81 {
  margin-bottom: 81px !important;
}

@media screen and (max-width: 1980px) {
  .mb_81_xlg {
    margin-bottom: 81px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_81_lg {
    margin-bottom: 81px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_81_mlg {
    margin-bottom: 81px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_81_slg {
    margin-bottom: 81px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_81_md {
    margin-bottom: 81px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_81_6ls {
    margin-bottom: 81px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_81_5ls {
    margin-bottom: 81px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_81_sm {
    margin-bottom: 81px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_81_msm {
    margin-bottom: 81px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_81_xs {
    margin-bottom: 81px !important;
  }
}
.mb_82 {
  margin-bottom: 82px !important;
}

@media screen and (max-width: 1980px) {
  .mb_82_xlg {
    margin-bottom: 82px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_82_lg {
    margin-bottom: 82px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_82_mlg {
    margin-bottom: 82px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_82_slg {
    margin-bottom: 82px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_82_md {
    margin-bottom: 82px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_82_6ls {
    margin-bottom: 82px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_82_5ls {
    margin-bottom: 82px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_82_sm {
    margin-bottom: 82px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_82_msm {
    margin-bottom: 82px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_82_xs {
    margin-bottom: 82px !important;
  }
}
.mb_83 {
  margin-bottom: 83px !important;
}

@media screen and (max-width: 1980px) {
  .mb_83_xlg {
    margin-bottom: 83px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_83_lg {
    margin-bottom: 83px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_83_mlg {
    margin-bottom: 83px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_83_slg {
    margin-bottom: 83px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_83_md {
    margin-bottom: 83px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_83_6ls {
    margin-bottom: 83px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_83_5ls {
    margin-bottom: 83px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_83_sm {
    margin-bottom: 83px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_83_msm {
    margin-bottom: 83px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_83_xs {
    margin-bottom: 83px !important;
  }
}
.mb_84 {
  margin-bottom: 84px !important;
}

@media screen and (max-width: 1980px) {
  .mb_84_xlg {
    margin-bottom: 84px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_84_lg {
    margin-bottom: 84px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_84_mlg {
    margin-bottom: 84px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_84_slg {
    margin-bottom: 84px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_84_md {
    margin-bottom: 84px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_84_6ls {
    margin-bottom: 84px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_84_5ls {
    margin-bottom: 84px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_84_sm {
    margin-bottom: 84px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_84_msm {
    margin-bottom: 84px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_84_xs {
    margin-bottom: 84px !important;
  }
}
.mb_85 {
  margin-bottom: 85px !important;
}

@media screen and (max-width: 1980px) {
  .mb_85_xlg {
    margin-bottom: 85px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_85_lg {
    margin-bottom: 85px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_85_mlg {
    margin-bottom: 85px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_85_slg {
    margin-bottom: 85px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_85_md {
    margin-bottom: 85px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_85_6ls {
    margin-bottom: 85px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_85_5ls {
    margin-bottom: 85px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_85_sm {
    margin-bottom: 85px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_85_msm {
    margin-bottom: 85px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_85_xs {
    margin-bottom: 85px !important;
  }
}
.mb_86 {
  margin-bottom: 86px !important;
}

@media screen and (max-width: 1980px) {
  .mb_86_xlg {
    margin-bottom: 86px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_86_lg {
    margin-bottom: 86px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_86_mlg {
    margin-bottom: 86px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_86_slg {
    margin-bottom: 86px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_86_md {
    margin-bottom: 86px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_86_6ls {
    margin-bottom: 86px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_86_5ls {
    margin-bottom: 86px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_86_sm {
    margin-bottom: 86px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_86_msm {
    margin-bottom: 86px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_86_xs {
    margin-bottom: 86px !important;
  }
}
.mb_87 {
  margin-bottom: 87px !important;
}

@media screen and (max-width: 1980px) {
  .mb_87_xlg {
    margin-bottom: 87px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_87_lg {
    margin-bottom: 87px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_87_mlg {
    margin-bottom: 87px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_87_slg {
    margin-bottom: 87px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_87_md {
    margin-bottom: 87px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_87_6ls {
    margin-bottom: 87px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_87_5ls {
    margin-bottom: 87px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_87_sm {
    margin-bottom: 87px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_87_msm {
    margin-bottom: 87px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_87_xs {
    margin-bottom: 87px !important;
  }
}
.mb_88 {
  margin-bottom: 88px !important;
}

@media screen and (max-width: 1980px) {
  .mb_88_xlg {
    margin-bottom: 88px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_88_lg {
    margin-bottom: 88px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_88_mlg {
    margin-bottom: 88px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_88_slg {
    margin-bottom: 88px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_88_md {
    margin-bottom: 88px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_88_6ls {
    margin-bottom: 88px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_88_5ls {
    margin-bottom: 88px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_88_sm {
    margin-bottom: 88px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_88_msm {
    margin-bottom: 88px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_88_xs {
    margin-bottom: 88px !important;
  }
}
.mb_89 {
  margin-bottom: 89px !important;
}

@media screen and (max-width: 1980px) {
  .mb_89_xlg {
    margin-bottom: 89px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_89_lg {
    margin-bottom: 89px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_89_mlg {
    margin-bottom: 89px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_89_slg {
    margin-bottom: 89px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_89_md {
    margin-bottom: 89px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_89_6ls {
    margin-bottom: 89px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_89_5ls {
    margin-bottom: 89px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_89_sm {
    margin-bottom: 89px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_89_msm {
    margin-bottom: 89px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_89_xs {
    margin-bottom: 89px !important;
  }
}
.mb_90 {
  margin-bottom: 90px !important;
}

@media screen and (max-width: 1980px) {
  .mb_90_xlg {
    margin-bottom: 90px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_90_lg {
    margin-bottom: 90px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_90_mlg {
    margin-bottom: 90px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_90_slg {
    margin-bottom: 90px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_90_md {
    margin-bottom: 90px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_90_6ls {
    margin-bottom: 90px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_90_5ls {
    margin-bottom: 90px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_90_sm {
    margin-bottom: 90px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_90_msm {
    margin-bottom: 90px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_90_xs {
    margin-bottom: 90px !important;
  }
}
.mb_91 {
  margin-bottom: 91px !important;
}

@media screen and (max-width: 1980px) {
  .mb_91_xlg {
    margin-bottom: 91px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_91_lg {
    margin-bottom: 91px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_91_mlg {
    margin-bottom: 91px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_91_slg {
    margin-bottom: 91px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_91_md {
    margin-bottom: 91px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_91_6ls {
    margin-bottom: 91px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_91_5ls {
    margin-bottom: 91px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_91_sm {
    margin-bottom: 91px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_91_msm {
    margin-bottom: 91px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_91_xs {
    margin-bottom: 91px !important;
  }
}
.mb_92 {
  margin-bottom: 92px !important;
}

@media screen and (max-width: 1980px) {
  .mb_92_xlg {
    margin-bottom: 92px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_92_lg {
    margin-bottom: 92px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_92_mlg {
    margin-bottom: 92px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_92_slg {
    margin-bottom: 92px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_92_md {
    margin-bottom: 92px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_92_6ls {
    margin-bottom: 92px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_92_5ls {
    margin-bottom: 92px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_92_sm {
    margin-bottom: 92px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_92_msm {
    margin-bottom: 92px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_92_xs {
    margin-bottom: 92px !important;
  }
}
.mb_93 {
  margin-bottom: 93px !important;
}

@media screen and (max-width: 1980px) {
  .mb_93_xlg {
    margin-bottom: 93px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_93_lg {
    margin-bottom: 93px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_93_mlg {
    margin-bottom: 93px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_93_slg {
    margin-bottom: 93px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_93_md {
    margin-bottom: 93px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_93_6ls {
    margin-bottom: 93px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_93_5ls {
    margin-bottom: 93px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_93_sm {
    margin-bottom: 93px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_93_msm {
    margin-bottom: 93px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_93_xs {
    margin-bottom: 93px !important;
  }
}
.mb_94 {
  margin-bottom: 94px !important;
}

@media screen and (max-width: 1980px) {
  .mb_94_xlg {
    margin-bottom: 94px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_94_lg {
    margin-bottom: 94px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_94_mlg {
    margin-bottom: 94px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_94_slg {
    margin-bottom: 94px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_94_md {
    margin-bottom: 94px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_94_6ls {
    margin-bottom: 94px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_94_5ls {
    margin-bottom: 94px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_94_sm {
    margin-bottom: 94px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_94_msm {
    margin-bottom: 94px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_94_xs {
    margin-bottom: 94px !important;
  }
}
.mb_95 {
  margin-bottom: 95px !important;
}

@media screen and (max-width: 1980px) {
  .mb_95_xlg {
    margin-bottom: 95px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_95_lg {
    margin-bottom: 95px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_95_mlg {
    margin-bottom: 95px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_95_slg {
    margin-bottom: 95px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_95_md {
    margin-bottom: 95px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_95_6ls {
    margin-bottom: 95px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_95_5ls {
    margin-bottom: 95px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_95_sm {
    margin-bottom: 95px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_95_msm {
    margin-bottom: 95px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_95_xs {
    margin-bottom: 95px !important;
  }
}
.mb_96 {
  margin-bottom: 96px !important;
}

@media screen and (max-width: 1980px) {
  .mb_96_xlg {
    margin-bottom: 96px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_96_lg {
    margin-bottom: 96px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_96_mlg {
    margin-bottom: 96px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_96_slg {
    margin-bottom: 96px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_96_md {
    margin-bottom: 96px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_96_6ls {
    margin-bottom: 96px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_96_5ls {
    margin-bottom: 96px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_96_sm {
    margin-bottom: 96px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_96_msm {
    margin-bottom: 96px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_96_xs {
    margin-bottom: 96px !important;
  }
}
.mb_97 {
  margin-bottom: 97px !important;
}

@media screen and (max-width: 1980px) {
  .mb_97_xlg {
    margin-bottom: 97px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_97_lg {
    margin-bottom: 97px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_97_mlg {
    margin-bottom: 97px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_97_slg {
    margin-bottom: 97px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_97_md {
    margin-bottom: 97px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_97_6ls {
    margin-bottom: 97px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_97_5ls {
    margin-bottom: 97px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_97_sm {
    margin-bottom: 97px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_97_msm {
    margin-bottom: 97px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_97_xs {
    margin-bottom: 97px !important;
  }
}
.mb_98 {
  margin-bottom: 98px !important;
}

@media screen and (max-width: 1980px) {
  .mb_98_xlg {
    margin-bottom: 98px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_98_lg {
    margin-bottom: 98px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_98_mlg {
    margin-bottom: 98px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_98_slg {
    margin-bottom: 98px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_98_md {
    margin-bottom: 98px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_98_6ls {
    margin-bottom: 98px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_98_5ls {
    margin-bottom: 98px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_98_sm {
    margin-bottom: 98px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_98_msm {
    margin-bottom: 98px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_98_xs {
    margin-bottom: 98px !important;
  }
}
.mb_99 {
  margin-bottom: 99px !important;
}

@media screen and (max-width: 1980px) {
  .mb_99_xlg {
    margin-bottom: 99px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_99_lg {
    margin-bottom: 99px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_99_mlg {
    margin-bottom: 99px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_99_slg {
    margin-bottom: 99px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_99_md {
    margin-bottom: 99px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_99_6ls {
    margin-bottom: 99px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_99_5ls {
    margin-bottom: 99px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_99_sm {
    margin-bottom: 99px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_99_msm {
    margin-bottom: 99px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_99_xs {
    margin-bottom: 99px !important;
  }
}
.mb_100 {
  margin-bottom: 100px !important;
}

@media screen and (max-width: 1980px) {
  .mb_100_xlg {
    margin-bottom: 100px !important;
  }
}
@media screen and (max-width: 1366px) {
  .mb_100_lg {
    margin-bottom: 100px !important;
  }
}
@media screen and (max-width: 1024px) {
  .mb_100_mlg {
    margin-bottom: 100px !important;
  }
}
@media screen and (max-width: 980px) {
  .mb_100_slg {
    margin-bottom: 100px !important;
  }
}
@media screen and (max-width: 834px) {
  .mb_100_md {
    margin-bottom: 100px !important;
  }
}
@media screen and (max-width: 667px) {
  .mb_100_6ls {
    margin-bottom: 100px !important;
  }
}
@media screen and (max-width: 568px) {
  .mb_100_5ls {
    margin-bottom: 100px !important;
  }
}
@media screen and (max-width: 428px) {
  .mb_100_sm {
    margin-bottom: 100px !important;
  }
}
@media screen and (max-width: 375px) {
  .mb_100_msm {
    margin-bottom: 100px !important;
  }
}
@media screen and (max-width: 320px) {
  .mb_100_xs {
    margin-bottom: 100px !important;
  }
}
.mt_auto {
  margin-top: auto;
}

@media screen and (max-width: 1980px) {
  .mt_auto--xlg {
    margin-top: auto;
  }
}
@media screen and (max-width: 1366px) {
  .mt_auto--lg {
    margin-top: auto;
  }
}
@media screen and (max-width: 1024px) {
  .mt_auto--mlg {
    margin-top: auto;
  }
}
@media screen and (max-width: 980px) {
  .mt_auto--slg {
    margin-top: auto;
  }
}
@media screen and (max-width: 834px) {
  .mt_auto--md {
    margin-top: auto;
  }
}
@media screen and (max-width: 667px) {
  .mt_auto--6ls {
    margin-top: auto;
  }
}
@media screen and (max-width: 568px) {
  .mt_auto--5ls {
    margin-top: auto;
  }
}
@media screen and (max-width: 428px) {
  .mt_auto--sm {
    margin-top: auto;
  }
}
@media screen and (max-width: 375px) {
  .mt_auto--msm {
    margin-top: auto;
  }
}
@media screen and (max-width: 320px) {
  .mt_auto--xs {
    margin-top: auto;
  }
}
.mb_auto {
  margin-bottom: auto;
}

@media screen and (max-width: 1980px) {
  .mb_auto--xlg {
    margin-bottom: auto;
  }
}
@media screen and (max-width: 1366px) {
  .mb_auto--lg {
    margin-bottom: auto;
  }
}
@media screen and (max-width: 1024px) {
  .mb_auto--mlg {
    margin-bottom: auto;
  }
}
@media screen and (max-width: 980px) {
  .mb_auto--slg {
    margin-bottom: auto;
  }
}
@media screen and (max-width: 834px) {
  .mb_auto--md {
    margin-bottom: auto;
  }
}
@media screen and (max-width: 667px) {
  .mb_auto--6ls {
    margin-bottom: auto;
  }
}
@media screen and (max-width: 568px) {
  .mb_auto--5ls {
    margin-bottom: auto;
  }
}
@media screen and (max-width: 428px) {
  .mb_auto--sm {
    margin-bottom: auto;
  }
}
@media screen and (max-width: 375px) {
  .mb_auto--msm {
    margin-bottom: auto;
  }
}
@media screen and (max-width: 320px) {
  .mb_auto--xs {
    margin-bottom: auto;
  }
}
.ml_auto {
  margin-left: auto;
}

@media screen and (max-width: 1980px) {
  .ml_auto--xlg {
    margin-left: auto;
  }
}
@media screen and (max-width: 1366px) {
  .ml_auto--lg {
    margin-left: auto;
  }
}
@media screen and (max-width: 1024px) {
  .ml_auto--mlg {
    margin-left: auto;
  }
}
@media screen and (max-width: 980px) {
  .ml_auto--slg {
    margin-left: auto;
  }
}
@media screen and (max-width: 834px) {
  .ml_auto--md {
    margin-left: auto;
  }
}
@media screen and (max-width: 667px) {
  .ml_auto--6ls {
    margin-left: auto;
  }
}
@media screen and (max-width: 568px) {
  .ml_auto--5ls {
    margin-left: auto;
  }
}
@media screen and (max-width: 428px) {
  .ml_auto--sm {
    margin-left: auto;
  }
}
@media screen and (max-width: 375px) {
  .ml_auto--msm {
    margin-left: auto;
  }
}
@media screen and (max-width: 320px) {
  .ml_auto--xs {
    margin-left: auto;
  }
}
.mr_auto {
  margin-right: auto;
}

@media screen and (max-width: 1980px) {
  .mr_auto--xlg {
    margin-right: auto;
  }
}
@media screen and (max-width: 1366px) {
  .mr_auto--lg {
    margin-right: auto;
  }
}
@media screen and (max-width: 1024px) {
  .mr_auto--mlg {
    margin-right: auto;
  }
}
@media screen and (max-width: 980px) {
  .mr_auto--slg {
    margin-right: auto;
  }
}
@media screen and (max-width: 834px) {
  .mr_auto--md {
    margin-right: auto;
  }
}
@media screen and (max-width: 667px) {
  .mr_auto--6ls {
    margin-right: auto;
  }
}
@media screen and (max-width: 568px) {
  .mr_auto--5ls {
    margin-right: auto;
  }
}
@media screen and (max-width: 428px) {
  .mr_auto--sm {
    margin-right: auto;
  }
}
@media screen and (max-width: 375px) {
  .mr_auto--msm {
    margin-right: auto;
  }
}
@media screen and (max-width: 320px) {
  .mr_auto--xs {
    margin-right: auto;
  }
}
.ms_auto {
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1980px) {
  .ms_auto--xlg {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 1366px) {
  .ms_auto--lg {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 1024px) {
  .ms_auto--mlg {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 980px) {
  .ms_auto--slg {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 834px) {
  .ms_auto--md {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 667px) {
  .ms_auto--6ls {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 568px) {
  .ms_auto--5ls {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 428px) {
  .ms_auto--sm {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 375px) {
  .ms_auto--msm {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 320px) {
  .ms_auto--xs {
    margin-left: auto;
    margin-right: auto;
  }
}
.text_bold {
  font-weight: bold;
}

.alignRight {
  text-align: right;
}

.textColor_error {
  color: #d9534f !important;
}