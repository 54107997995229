@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// contact form7の上書きCSS
// ============================================================

.wpcf7-spinner{
  display: none!important;
}

.name_filed{
  input[type=text]{
    width: calc(100% / 2 - .5rem);
  }
}

.codedropz-upload-wrapper{
  display: none;
}

.dnd-upload-status{
  display: none;
}

.mwform-file-delete{
  display: none!important;
}

.mw-wp-form_image{
  display: none!important;
}

// 確認ページ固有のスタイル書き換え
// ============================================================
.mw_wp_form_confirm{

  .confirm-hidden{
    display: none;
  }
  
  .table02 th{
    font-size: .75rem;
  }

  .fileName{
    font-size: 1rem;
  }

}

// バリデーションエラー時のメッセージ調整
// ============================================================

.mw_wp_form .error{
  padding: 5px 0;
}