@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;

// top_introduction
// ============================================================

.top_introduction_inner{
  width: 100%;
  max-width: 500px;
  background-color: #fff;
  padding: function.px2rem(24px) function.px2rem(26px);
  margin: 0 auto;
  @include mix.max-media(var.$screen-msm){
    padding: function.px2rem(20px) function.px2rem(13px);
  }
}

.introduction_heading_container{
  width: 100%;
  text-align: center;
}

.top_introduction_heading{
  position: relative;
  display: inline-block;
  font-size: 25px;
  
  @include mix.min-media(var.$screen-md){
    font-size: 30px;
  }

  &:before{
    position: absolute;
    left: -1.85em;
    bottom: -5px;
    width: 1.5em;
    display: block;
    content: url('../img/top/hand_img.svg');
  }
}

// top_operating_company
// ============================================================

.top_operating_company_inner{
  width: 100%;
  max-width: 500px;
  background-color: transparent;
  padding: 36px 0;
  margin: 0 auto;
}


// operating_company
// ============================================================

.operating_company_textUnit{
  padding: 0;
}

.company_img{
  width: 90%;
  max-width : 400px;
  margin: 0 auto;
  @include mix.max-media(var.$screen-msm){
    width: 72%;
  }
}

// copyright_group
// ============================================================
#brand_logo02{
  fill: #fff;
}

.copyright_group{
  width: 100%;
  max-width: 100%;
}

.brand_logo_footer{
  width: 100%;
  text-align: center;

}

.brand_logo_lighter{
  width: 45%;
  margin: 0 auto;
  text-align: center;

  @include mix.min-media(var.$screen-md){
    width: 25%;
  }
}

.copyright{
  text-align: center;
  font-family: var.$font-family-base;
  font-size: 10px;
  color: #fff;

  @include mix.min-media(var.$screen-md){
    font-size: 12px;
  }
}
