@use "../variables" as var;
@use "../mixins" as mix;
@use "../functions" as function;


.textUnit{
  margin-bottom: 2rem;
}

.thanks_textUnit{
  p{
    color: #fff;
  }
}



// 中の文章が白い時のためのクラス

.textUnit.isColorWhite{
  p{
    color: #fff;
  }
}

.annotation{
  p{
    font-size: 12px;
  }
}